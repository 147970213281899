.page-checkout {
  font-family: 'Open Sans', sans-serif;
  color: #606060;
  table.checkout-review {
    margin: 0;
    td,
    tr {
      margin: 0;
      padding: 0;
    }
  }
  fieldset {
    border: none;
    padding: 0;
    margin: 0;
  }
  label {
    text-transform: uppercase;
    margin-bottom: 10px;
  }
  h3 {
    margin-bottom: 30px;
  }
  .sub-title {
    color: #424242;
    font-size: 16px;
    font-weight: 700;
    display: block;
    margin-bottom: 20px;
  }
  .description {
    margin: 0;
    width: 100%;
    padding-top: 5px;
  }
  .checkout-help {
    display: none;
  }
  .columns .form-item {
    float: none;
    margin-right: 0;
  }
  .columns .commerce_payment {
    #payment-details {
      clear: both;
    }
    .form-type-radio {
      position: relative;
      width: 145px;
      height: 115px;
      float: left;
      margin-right: 30px;
      input[type="radio"] {
        display: none;
      }
    }
    input[type="radio"] ~ label {
      display: block;
      width: 100%;
      height: 100%;
      border: 3px solid #f5f5f5;
      border-radius: 5px;
      background-color: #f5f5f5;
      background-repeat: no-repeat;
      background-position: 50%;
      text-indent: -9999px;
      * {
        display: none;
      }
    }
    input[type="radio"]:checked ~ label {
      border-color: #ccc;
      background-color: transparent;
    }
    input[type="radio"]#edit-commerce-payment-payment-method-paypal-wppcommerce-payment-paypal-wpp ~ label {
      background-image: url('../images/credit-card--default.png');
    }
    input[type="radio"]#edit-commerce-payment-payment-method-paypal-wppcommerce-payment-paypal-wpp:checked ~ label {
      background-image: url('../images/credit-card--active.png');
    }
    input[type="radio"]#edit-commerce-payment-payment-method-paypal-wpscommerce-payment-paypal-wps ~ label {
      background-image: url('../images/paypal--default.png');
    }
    input[type="radio"]#edit-commerce-payment-payment-method-paypal-wpscommerce-payment-paypal-wps:checked ~ label {
      background-image: url('../images/paypal--active.png');
    }
  }
  .columns .commerce-credit-card-expiration {
    display: inline-flex;
    align-items: flex-end;
    width: calc((100% / 3) * 2);
    white-space: nowrap;
    .form-type-select {
      width: calc(100% / 2);
      margin-right: 15px;
    }
    .commerce-month-year-divider {
      display: none;
    }
    + .form-type-textfield {
      float: left;
      width: calc(100% / 3);
      padding-right: 15px;
      box-sizing: border-box;
      white-space: nowrap;
    }
  }
  .columns .form-item-customer-profile-shipping-commerce-customer-address-und-0-administrative-area,
  .columns .form-item-customer-profile-billing-commerce-customer-address-und-0-administrative-area {
    margin-right: 30px;
    width: calc(50% - 30px);
    float: left;
    + .form-type-textfield {
      display: inline-block;
      width: 50%;
    }
  }
  .columns .form-type-select {
    box-sizing: border-box;
    position: relative;
    &:last-child {
      margin-right: 0;
    }
    select {
      appearance: none;
      width: 100%;
      height: 50px;
      padding: 0 20px;
      background-color: #eee;
      color: #606060;
      font-weight: 600;
      border: 2px solid transparent;
      border-radius: 2px;
      transition: border-color 0.2s ease;
      &:focus {
        outline: none;
        border-color: $selection_color;
      }
    }
    select::-ms-expand {
      display: none;
    }
    &:after {
      content: "";
      box-sizing: border-box;
      display: block;
      position: absolute;
      right: 12px;
      bottom: 22px;
      width: 10px;
      height: 10px;
      border: 2px solid #424242;
      border-right: none;
      border-top: none;
      transform: rotate(-45deg);
      pointer-events: none;
    }
  }
  .columns input[type="text"],
  .columns input[type="password"] {
    width: 100%;
  }
  .columns input[type="text"][id*="edit-commerce-payment-payment-details-credit-card-number"] {
    background: url('../images/credit-card--text-field.png') no-repeat 96% 50%;
    padding-right: 60px;
  }
  .columns input[type="checkbox"],
  .columns input[type="radio"] {
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    transition: border-color 0.2s ease;
    cursor: pointer;
    &:focus {
      outline: none;
    }
    &:active {
      border-color: #b1b1b1;
    }
  }
  .columns input[type="checkbox"] {
    border: 1px solid #4a4a4a;
  }
  .columns input[type="checkbox"]:checked:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 45%;
    width: 6px;
    height: 3px;
    border: 2px solid #4a4a4a;
    border-right: none;
    border-top: none;
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .columns input[type="radio"] {
    border: 2px solid #4a4a4a;
    border-radius: 50%;
  }
  .columns input[type="radio"]:checked:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: $selection_color;
    border-radius: 50%;
  }
  .fieldset-legend {
    font-family: 'Gilroy', sans-serif;
    font-size: 24px;
    font-weight: 800;
    color: #424242;
    text-transform: uppercase;
  }
  #edit-cancel {
    border: none;
  }
  #edit-account-form-select-login-continue,
  #edit-account-form-select-register-continue {
    display: inline-block;
    font-family: $font-title;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    text-transform: uppercase;
    padding: 15.5px 37px;
    @include border_radius(28px);
    color: #fffefe;
    background-color: #0ab29c;
    border: none;
    &:hover {
      background-color: #2fbdab;
      color: #ffffff;
    }
  }
  .content-wrapper {
    margin-bottom: 0 !important;
    @include clearfix;
  }


  #commerce-checkout-form-checkout > div,
  #commerce-checkout-form-shipping > div,
  #commerce-checkout-form-review > div,
  #commerce-checkout-form-login > div {
    display: flex;
    flex-wrap: wrap;
    max-width: 970px;
    margin: 0 auto;
  }
  .commerce_user_profile_pane,
  .customer_profile_shipping,
  #customer-profile-billing-ajax-wrapper,
  .cvp-billing_rewiew_1-commerce_checkout_pane_1,
  #commerce-shipping-service-ajax-wrapper,
  .checkout_review,
  .commerce_payment {
    width: calc(100% / 2);
    box-sizing: border-box;
  }
  .commerce_user_profile_pane,
  .cvp-billing_rewiew_1-commerce_checkout_pane_1,
  .checkout_review {
    padding-right: 50px;
  }
  .customer_profile_shipping,
  #commerce-shipping-service-ajax-wrapper,
  .commerce_payment {
    padding-left: 50px;
  }
  #customer-profile-billing-ajax-wrapper {
    margin-left: auto;
    width: calc((100% / 2) - 50px);
    .form-item-customer-profile-billing-commerce-customer-profile-copy {
      display: flex;
      align-items: center;
    }
    .fieldset-legend {
      display: none;
    }
    label.option {
      text-transform: capitalize;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 15px;
      color: #424242;
    }
    .form-checkbox {
      margin-right: 10px;
      flex: 0 0 auto;
    }
  }

  #edit-commerce-payment-payment-method {
    @include clearfix;
  }
  .your-data,
  .your-order {
    background-color: #f5f5f5;
    color: #424242;
    padding: 30px;
    margin-bottom: 30px;
    @include clearfix;
    a {
      color: #424242;
    }
  }
  .your-data {
    font-size: 14px;
    .views-fieldset {
      margin-bottom: 30px;
    }
    .billing-info,
    .shipping-info {
      box-sizing: border-box;
      width: 50%;
      float: left;
    }
    .billing-info {
      padding-right: 20px;
    }
  }
  .your-order {
    .shipping-price,
    .total-price {
      float: left;
    }
    .shipping-price {
      margin-right: 38px;
      span.views-label {
        font-weight: 700;
      }
    }
    table {
      margin: 0;
      tr,
      td {
        padding: 0;
        display: block;
        color: #424242;
        font-size: 16px;
      }
      tr {
        float: left;
        background: transparent;
        padding-right: 38px;
      }
      td:first-child {
        font-weight: 700;
      }
    }
  }
  .form-type-radio {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #424242;
    label {
      font-family: 'Gilroy', sans-serif;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
      text-transform: capitalize;
    }
    .form-radio {
      margin-right: 10px;
      flex: 0 0 auto;
    }
  }
  .checkout-buttons {
    width: 100%;
  }
}

.steps-of-checkout {
  font-family: 'Gilroy', sans-serif;
  font-size: 20px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  color: #424242;
  text-transform: uppercase;
  margin-bottom: 67px;
}
.step {
  margin-right: 50px;
  display: flex;
  align-items: center;
  &:last-child {
    margin-right: 0;
  }
  &__number {
    display: block;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 3px solid $selection_color;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    margin-right: 15px;
    color: $selection_color;
  }
}

.page-checkout-checkout .step-1,
.page-checkout-shipping .step-2,
.page-checkout-review .step-3 {
  color: $selection_color;
  .step__number {
    color: #fff;
    background-color: $selection_color;
  }
}

.checkout-buttons {
  color: #919191;
  .fieldset-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 60px;
  }
  .continue-wrap {
    position: relative;
    margin-bottom: 50px;
    min-width: 250px;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 430px;
      height: 2px;
      background-color: #eee;
    }
    &:before {
      right: calc(100% + 30px);
    }
    &:after {
      left: calc(100% + 30px);
    }
  }
  .button-operator {
    font-family: 'Gilroy', sans-serif;
    margin-bottom: 15px;
    font-size: 20px;
    font-style: italic;
    letter-spacing: 1.6px;
    color: #919191;
  }
  input.checkout-continue {
    width: 100%;
    letter-spacing: 1.8px;
  }
  input.checkout-back {
    text-transform: capitalize;
    position: relative;
    background: url('../images/left-arrow.svg') no-repeat 31px 50% #424242;
    background-size: 22px;
    padding-left: 67px;
    &:hover {
      background: url('../images/left-arrow.svg') no-repeat 31px 50% #5e5e5e;
      background-size: 22px;
    }
    &:focus {
      background: url('../images/left-arrow.svg') no-repeat 31px 50% #5e5e5e;
      background-size: 22px;
      color: #fffefe;
      text-decoration: none;
    }
  }
}

#commerce-checkout-form-login .checkout-buttons {
  .fieldset-wrapper {
    padding-top: 20px;
    align-items: flex-start;
  }
  .continue-wrap,
  .button-operator {
    display: none;
  }
}

.checkout-completion-message {
  text-align: center;
  padding: 90px 0 60px;
  p {
    font-family: 'A Garamond Pro', sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: #424242;
    margin-bottom: 120px;
  }
  .title {
    font-family: 'Gilroy', sans-serif;
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 5px;
    margin-bottom: 41px;
  }
  .button-border-green {
    position: relative;
    box-sizing: border-box;
    &:before,
    &:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 430px;
      height: 2px;
      background-color: #eee;
    }
    &:before {
      right: calc(100% + 30px);
    }
    &:after {
      left: calc(100% + 30px);
    }
  }
}
