.product-categories-page {
	.view-header {
    padding: 16px 35px;
    margin-bottom: 30px;
    background-color: #f5f5f5;
    overflow: hidden;
    .total-found {
      float: left;
      p {
        margin: 0;
        color: #424242;
        font-family: $font-title;
        font-size: 24px;
        font-weight: 800;
        text-transform: uppercase;
      }
    }
    .inline-display {
      float: right;
      width: 30px;
      height: 28px;
      background: url(../images/lines.png) center center no-repeat;
      margin-right: 15px;
    }
    .inline-display.active {
      background: url(../images/lines-active.png) center center no-repeat;
    }
    .grid-display {
      float: right;
      width: 44px;
      height: 28px;
      background: url(../images/grid.png) center center no-repeat;
    }
    .grid-display.active {
      background: url(../images/grid-active.png) center center no-repeat;
    }
    .inline-display:hover, .grid-display:hover {
      cursor: pointer;
    }
  }
  .item-list-pager {
    position: relative;
    margin: 50px 0 0 0;
    padding-top: 30px;
    display: block !important;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 100%;
      width: 100%;
      height: 2px;
      background-color: #eee;
    }
    ul.pager {
      margin: 0;
      height: 28px;
      li {
        padding: 0;
        height: 28px;
        margin: 0 40px 0 0;
      }
      li.pager-first, li.pager-last {
        display: none;
      }
      li.pager-previous {
        display: inline-block;
        margin: 0;
        float: left;
        a {
          display: inline-block;
          width: 16px;
          height: 28px;
          background: url(../images/arrow-left.png)
        }
      }
      li.pager-next {
        display: inline-block;
        margin: 0;
        float: right;
        a {
          display: inline-block;
          width: 16px;
          height: 28px;
          background: url(../images/arrow-right.png)
        }
      }
      li.pager-item {
        margin-bottom: 0;
        a {
          color: #b1b1b1;
          font-family: $font-title;
          font-size: 24px;
          font-weight: 800;
        }        
      }
      li.pager-current {
        margin-bottom: 0;
        color: #424242;
        font-family: $font-title;
        font-size: 24px;
        font-weight: 800;
      }
    }
  }

  .views-exposed-widgets {
    display: flex;
    justify-content: flex-end;
  }
}
