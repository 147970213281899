#cboxMiddleLeft, 
#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight,
#cboxClose,
.commerce-add-to-cart .div #plus {
  display: none;
}
#cboxContent {
  box-sizing: border-box;
  height: 480px !important;
  width: 100% !important;
  background: #fff;
}
#cboxLoadedContent {
  border: none;
  overflow: auto;
  margin-bottom: 0;
}
#cboxLoadedContent,
#cboxWrapper {
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box;
}
#cboxWrapper {
  border-radius: 0 !important;
}
#cboxOverlay {
  opacity: 0.55 !important;
}
#cboxLoadingGraphic {
  background: none;
  height: 30px;
  width: 30px;
  border: 5px solid rgba(10, 178, 155, 0.45);
  border-top: 5px solid rgba(10, 178, 155, 1);
  border-radius: 30px;
  animation: rotate 1s infinite linear;
  top: 50%; left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
#colorbox,
.page-product-item {
  position: relative;
  #messages {
    display: none;
  }
  .cbox-container {
    position: absolute;
    right: 0; top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 370px;
    padding: 30px 30px 40px;
    box-sizing: border-box;
  }
  .product-title a {
    font-family: $font-title;
    color: #424242;
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase !important;
    text-decoration: none;
  }
  .price-wrap {
    padding-top: 6px;
    display: flex;
    align-items: center;
  }
  .views-field-commerce-price {
    font-family: "A Garamond Pro";
    color:  #0ab29c;
    font-size: 28px;
    font-weight: 700;
    line-height: 18px;
    margin-right: 8px;
  }
  .views-field-field-weight {
    color: #b1b1b1;
    font-family: "A Garamond Pro";
    font-size: 18px;
    font-weight: 700;
    line-height: 28px;
  }
  .views-field-field-commerce-image {
    position: absolute;
    width: 400px;
    height: 100%;
    img {
      display: block;
    }
  }
  .views-field-body {
    padding-top: 16px;
    p {
      font-family: "A Garamond Pro";
      color:  #424242;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      margin: 0;
    }
  }
  .main-operations {
    margin-top: auto;
  }
  .form-item-quantity {
    margin-top: -71.7px;
    margin-bottom: 30px;
    input {
      box-sizing: border-box;
      border-left: none;
      border-right: none;
      border-top: solid 3px #b1b1b1;
      border-bottom: solid 3px #b1b1b1;
      width: 72px;
      height: 40px;
      text-align: center;
      font-size: 24px;
      color: #424242;
      font-weight: 600;
      margin-left: 56px;
      border-radius: 0;
    }
  }
  .form-item-quantity label,
  .label_price {
    display: block;
    font-family: $font-title;
    color:  #424242;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 8px;
  }
  .plus,
  .minus {
    font-family: 'Gilroy', sans-serif;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: solid 3px #b1b1b1;
    color: #b1b1b1;
    width: 56px;
    height: 40px;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
    user-select: none;
  }
  .minus {
    border-radius: 20px 0 0 20px;
  }
  .plus {
    left: 72px;
    border-radius: 0 20px 20px 0;
  }
  .price_total {
    float: right;
    margin-top: -32px;
    .label_price {
      margin-bottom: 16px;
    }
  }
  .views-field-commerce-total {
    width: 15%;
    margin-top: 2%;
    margin-left: 80%;
  }
  .price {
    font-family: "A Garamond Pro";
    color: #424242;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px;
  }
  .views-field-add-to-cart-form {
    width: 100%;
  }
  .form-submit {
    width: 100%;
    display: inline-block;
    font-family: $font-title;
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    text-transform: uppercase;
    padding: 15.5px 37px;
    border-radius: 30px;
    color: #fffefe;
    background-color: #0ab29c;
    border: none;
    letter-spacing: 1px;
    &:hover {
      background-color: #2fbdab;
      color: #ffffff;
    }
  }
}

#colorbox {
  .views-field-body {
    position: relative;
    min-height: 150px;
    max-height: 150px;
    overflow: hidden;
    &:after {
      content: "";
      display: block;
      position: absolute;
      bottom: 0; left: 0;
      width: 100%;
      height: 50px;
      background-image: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,0.8) 30%, rgba(255,255,255,1) 100%);
    }
  }
}

.page-product-item {
  .cbox-container {
    position: relative;
    width: 50%;
    padding: 30px 30px 40px 50px;
  }
  .views-field-field-commerce-image {
    position: relative;
    float: left;
    width: 35%;
    img {
      width: 100%;
    }
  }
  .views-field-body {
    margin-bottom: 100px;
  }
  .form-submit {
    display: block;
    width: auto;
  }
  .price_total {
    position: absolute;
    left: 260px;
  }
}

.front .page-product-item .form-submit {
  margin: 0 auto;
}

.front .page-product-item .views-field-commerce-price {
  line-height: inherit;
}