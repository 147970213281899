@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800");
@font-face {
  font-family: 'Andrea';
  src: url("../fonts/Andrea-Normal.otf"), url("../fonts/Andrea-Normal.woff");
  font-weight: normal;
  font-style: normal; }

@font-face {
  font-family: "A Garamond Pro";
  src: url("../fonts/Adobe Garamond Pro Regular.ttf");
  font-weight: normal; }

@font-face {
  font-family: "A Garamond Pro";
  src: url("../fonts/Adobe Garamond Pro Bold.ttf");
  font-weight: bold; }

@font-face {
  font-family: Gilroy;
  src: local("Gilroy"), url("../fonts/Gilroy-light/Gilroy-Light.otf") format("opentype"), url("fonts/Gilroy-light/Gilroy-Light.woff") format("woff"), url("fonts/Gilroy-light/Gilroy-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal; }

@font-face {
  font-family: Gilroy;
  src: local("Gilroy"), url("../fonts/Gilroy-bold/Gilroy-ExtraBold.otf") format("opentype"), url("fonts/Gilroy-bold/Gilroy-ExtraBold.woff") format("woff"), url("fonts/Gilroy-bold/Gilroy-ExtraBold.ttf") format("truetype");
  font-weight: bold; }

/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block; }

/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1; }

/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */
[hidden] {
  display: none; }

body {
  margin: 0; }

ul li,
ul li.leaf {
  list-style: none; }

/*
 * Headings
 *
 * Addresses font sizes and margins set differently in IE6/7
 * Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5
 */
h1 {
  font-size: 50px;
  line-height: 50px;
  text-transform: uppercase; }

h2 {
  font-size: 36px;
  line-height: 36px; }

h3 {
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase; }

h1, h2, h3 {
  font-family: "Gilroy", sans-serif;
  color: #424242;
  font-weight: 600;
  margin: 0; }

.page a {
  text-decoration: none;
  color: #202020;
  font-family: "Open Sans", sans-serif;
  transition: all .2s; }

/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: thin dotted;
  text-decoration: none; }

/*
 * Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test
 */
a:active,
a:hover {
  outline: 0;
  text-decoration: none; }

p {
  font-family: "Open Sans", sans-serif;
  color: #202020;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px; }

pre,
code,
tt,
samp,
kbd,
var {
  font-family: Consolas, Monaco, 'Courier New', Courier, monospace, sans-serif; }

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */
abbr[title] {
  border-bottom: 1px dotted; }

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
 */
b,
strong {
  font-weight: 700; }

blockquote {
  margin: 1em 40px; }

/*
 * Addresses styling not present in S5, Chrome
 */
dfn {
  font-style: italic; }

/*
 * Addresses styling not present in IE6/7/8/9
 */
mark {
  background: #ff0;
  color: #000; }

/*
 * Addresses margins set differently in IE6/7
 */
p,
pre {
  margin: 0 0 1.5em; }

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em; }

/*
 * Improves readability of pre-formatted text in all browsers
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word; }

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */
q {
  quotes: none; }

q:before,
q:after {
  content: '';
  content: none; }

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
small,
sub,
sup {
  font-size: 75%; }

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/*=============================================================================
    Forms
   ========================================================================== */
/*
 * Corrects margin displayed oddly in IE6/7
 */
figure,
form {
  margin: 0; }

/*
 * Define consistent border, margin, and padding
 */
fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

/*
 * 1. Corrects color not being inherited in IE6/7/8/9
 * 2. Corrects text not wrapping in FF3
 * 3. Corrects alignment displayed oddly in IE6/7
 */
legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px; }

/*
 * 1. Corrects font size not being inherited in all browsers
 * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
 * 3. Improves appearance and consistency in all browsers
 */
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle; }

/*
 * Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
 */
button,
input {
  line-height: normal; }

/*
 * 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6
 */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible;
  transition: all .2s; }

/*
 * Re-set default cursor for disabled elements
 */
button[disabled],
input[disabled] {
  cursor: default; }

/*
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 * 3. Removes excess padding in IE7
 *    Known issue: excess padding remains in IE6
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px; }

/*
 * 1. Addresses appearance set to searchfield in S5, Chrome
 * 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
 */
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box; }

/*
 * Removes inner padding and search cancel button in S5, Chrome on OS X
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

/*
 * Removes inner padding and border in FF3+
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */
textarea {
  overflow: auto;
  vertical-align: top; }

#edit-cancel {
  text-decoration: none;
  text-transform: uppercase;
  color: #54d682;
  border: 1px solid #54d682;
  border-radius: 5px;
  background: #fff; }

/* Buttons */
a.tag-button {
  display: block;
  border: 2px solid #0ab29c;
  -moz-border-radius: 18px;
  -webkit-border-radius: 18px;
  border-radius: 18px;
  color: #0ab29c;
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  font-weight: 700;
  line-height: 36px;
  padding: 0px 15px;
  text-align: center;
  text-transform: capitalize; }

a.tag-button:hover {
  background-color: #0ab29c;
  color: white; }

a.button,
button.button,
#edit-checkout,
input[type="submit"]:not(.delete-line-item),
.checkout-buttons input.checkout-continue,
.checkout-buttons input.checkout-back,
#subscribe .form-submit,
#edit-cancel {
  display: inline-block;
  font-family: "Gilroy", sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  text-transform: uppercase;
  padding: 15.5px 37px;
  -moz-border-radius: 28px;
  -webkit-border-radius: 28px;
  border-radius: 28px; }

/* Grren buttons */
.checkout-buttons input.checkout-continue,
a.button-border-green {
  color: #0ab29c;
  border: 3px solid #0ab29c;
  padding: 13px 37px;
  background-color: transparent; }

.checkout-buttons input.checkout-continue:hover,
a.button-border-green:hover {
  background-color: #0ab29c;
  color: white; }

a.button-fill-green,
button.button-fill-green,
#edit-checkout,
input[type="submit"]:not(.delete-line-item) {
  color: #fffefe;
  background-color: #0ab29c;
  border: none; }

a.button-fill-green:hover,
button.button-fill-green:hover,
#edit-checkout:hover,
input[type="submit"]:not(.delete-line-item):hover {
  background-color: #2fbdab;
  color: #ffffff; }

/* Gray buttons */
a.button-border-gray,
button.button-border-gray {
  color: #424242;
  border: 3px solid #424242;
  padding: 13px 37px;
  background-color: transparent; }

a.button-border-gray:hover,
button.button-border-gray:hover {
  background-color: #424242;
  color: white; }

.checkout-buttons input.checkout-back,
a.button-fill-gray,
#edit-cancel {
  color: #fffefe;
  background-color: #424242; }

.checkout-buttons input.checkout-back:hover,
a.button-fill-gray:hover,
#edit-cancel:hover {
  background-color: #5e5e5e;
  color: #ffffff;
  text-decoration: none; }

.delete-line-item {
  width: 18px;
  height: 22px;
  border: none;
  background-color: transparent;
  background-image: url("../images/delete-button.svg");
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  text-indent: -9999px;
  vertical-align: top; }

#footer {
  width: 100%;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: auto;
  background-color: #232832;
  overflow: hidden;
  padding: 0 !important; }

#footer-columns {
  margin: 58px auto;
  max-width: 1170px;
  padding: 0 30px; }
  #footer-columns .region {
    display: inline-block;
    width: 22%;
    vertical-align: top;
    margin-right: 4%;
    float: left; }
    #footer-columns .region p, #footer-columns .region a {
      color: #bcbcbc;
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin: 0;
      text-decoration: none; }
    #footer-columns .region .footer-menu h2 {
      margin-top: 3px;
      margin-bottom: 15px;
      color: #fefefe;
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase; }
    #footer-columns .region .footer-menu ul {
      margin: 0;
      padding: 0;
      list-style: none; }
      #footer-columns .region .footer-menu ul li {
        list-style: none;
        margin: 0;
        padding: 8px 0 8px 0;
        border-bottom: 1px solid rgba(188, 188, 188, 0.5); }
        #footer-columns .region .footer-menu ul li a {
          letter-spacing: 0.04em; }
      #footer-columns .region .footer-menu ul li:first-child {
        padding-top: 0; }
    #footer-columns .region .footer-menu .contextual-links {
      margin: 0;
      padding: 0.25em 0; }
      #footer-columns .region .footer-menu .contextual-links li {
        margin: 0;
        padding: 0;
        border: none; }
        #footer-columns .region .footer-menu .contextual-links li a {
          margin: 0.25em 0;
          padding: 0.25em 1em 0.25em 0.5em; }
  #footer-columns .region:last-child {
    margin-right: 0; }
  #footer-columns .region-footer-firstcolumn .footer-site-branding {
    vertical-align: top; }
    #footer-columns .region-footer-firstcolumn .footer-site-branding div {
      display: inline-block;
      vertical-align: top; }
    #footer-columns .region-footer-firstcolumn .footer-site-branding h2 {
      color: white;
      font-family: "Gilroy", sans-serif;
      font-size: 32px;
      font-weight: 300;
      text-transform: none;
      margin-left: 3px;
      margin-bottom: 25px; }
  #footer-columns .region-footer-firstcolumn .company-description {
    margin-bottom: 30px; }
    #footer-columns .region-footer-firstcolumn .company-description p {
      line-height: 25px; }
  #footer-columns .region-footer-firstcolumn .social-network-images .icon {
    display: inline-block;
    width: 36px;
    height: 36px;
    margin-right: 5px; }
    #footer-columns .region-footer-firstcolumn .social-network-images .icon:hover {
      opacity: 0.8; }
  #footer-columns .region-footer-firstcolumn .social-network-images a {
    height: 36px;
    width: 36px;
    display: block; }
  #footer-columns .region-footer-firstcolumn .social-network-images .icon:last-child {
    margin-right: 0; }
  #footer-columns .region-footer-firstcolumn .social-network-images .twitter-icon {
    background: url(../images/social-network.png) 0 0 no-repeat; }
  #footer-columns .region-footer-firstcolumn .social-network-images .facebook-icon {
    background: url(../images/social-network.png) -50px 0 no-repeat; }
  #footer-columns .region-footer-firstcolumn .social-network-images .instagram-icon {
    background: url(../images/social-network.png) -102px 0 no-repeat; }
  #footer-columns .region-footer-firstcolumn .social-network-images .pinterest-icon {
    background: url(../images/social-network.png) -152px 0 no-repeat; }
  #footer-columns .region-footer-firstcolumn .social-network-images .google-plus-icon {
    background: url(../images/social-network.png) -102px 0 no-repeat; }
  #footer-columns .region-footer-firstcolumn .copyright-info {
    text-align: left;
    margin: 37px 0 0 0; }
    #footer-columns .region-footer-firstcolumn .copyright-info p {
      opacity: 0.7;
      line-height: 30px; }

#header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background: #232832;
  color: #fff;
  z-index: 10; }
  #header .menu-open {
    margin-top: 37px;
    float: right;
    width: 20px;
    height: 20px;
    background: url(../images/menu.png) center center no-repeat;
    cursor: pointer; }
  #header .menu-open:hover {
    background: url(../images/menu-hover.png) center center no-repeat; }

.admin-menu #header {
  top: 29px; }

#header-wrapper {
  position: relative;
  height: 92px;
  padding: 0 30px;
  width: auto !important; }

h1#site-name {
  font-size: 40px;
  font-weight: 300;
  line-height: 30px;
  text-transform: none; }
  h1#site-name a {
    color: #fff;
    font-family: "Gilroy", sans-serif; }

#branding {
  float: left;
  padding-top: 23px; }
  #branding #logo, #branding #name-and-slogan {
    float: left; }
  #branding #logo {
    margin-right: 13px;
    height: auto; }
  #branding #name-and-slogan {
    vertical-align: top; }
    #branding #name-and-slogan h1 {
      line-height: 50px; }
    #branding #name-and-slogan a {
      color: white;
      font-family: "Gilroy", sans-serif;
      font-size: 40px;
      font-weight: 300;
      line-height: 30px;
      vertical-align: text-top; }

.region-header {
  float: right; }
  .region-header .header-cart-block {
    position: static;
    display: inline-block;
    margin: 27px 34px 0 0;
    float: right; }
    .region-header .header-cart-block .view-main-content {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      box-sizing: border-box;
      position: absolute;
      top: 100%;
      right: 30px;
      background-color: #fff;
      color: #919191;
      width: 300px;
      padding: 30px;
      padding-top: 70px;
      box-shadow: 0 6px 25px 0 rgba(35, 40, 50, 0.16);
      display: none; }
      .region-header .header-cart-block .view-main-content .views-row {
        margin-bottom: 15px; }
        .region-header .header-cart-block .view-main-content .views-row:after {
          content: "";
          display: table;
          clear: both; }
      .region-header .header-cart-block .view-main-content .views-field-field-commerce-image {
        float: left;
        margin-right: 10px; }
      .region-header .header-cart-block .view-main-content .views-field-line-item-title {
        padding-left: 110px; }
      .region-header .header-cart-block .view-main-content .field-content {
        display: inline-block; }
      .region-header .header-cart-block .view-main-content .product-cart-title a,
      .region-header .header-cart-block .view-main-content .cart-total,
      .region-header .header-cart-block .view-main-content .field-items,
      .region-header .header-cart-block .view-main-content .empty-cart {
        font-family: 'Gilroy', sans-serif;
        font-weight: bold;
        color: #424242; }
      .region-header .header-cart-block .view-main-content .product-cart-title {
        font-size: 16px; }
      .region-header .header-cart-block .view-main-content .cart-total {
        position: absolute;
        top: 30px;
        left: 30px;
        font-size: 20px; }
      .region-header .header-cart-block .view-main-content .field-label,
      .region-header .header-cart-block .view-main-content .field-items {
        font-size: 18px; }
      .region-header .header-cart-block .view-main-content .field-name-commerce-order-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px; }
        .region-header .header-cart-block .view-main-content .field-name-commerce-order-total:after {
          display: none; }
      .region-header .header-cart-block .view-main-content .button {
        width: 100%;
        height: 44px;
        padding: 9.5px 37px;
        line-height: 18px;
        font-size: 18px;
        white-space: nowrap;
        word-wrap: normal; }
      .region-header .header-cart-block .view-main-content .item-list-pager {
        display: none; }
      .region-header .header-cart-block .view-main-content .empty-cart {
        font-size: 34px;
        line-height: 1.2;
        margin-top: -40px;
        margin-bottom: 20px; }
    .region-header .header-cart-block .my-cart-info {
      position: relative;
      background: url(../images/bag.png) left center no-repeat;
      padding-left: 32px;
      min-height: 36px;
      cursor: pointer; }
      .region-header .header-cart-block .my-cart-info .views-label {
        display: block;
        font-family: "Open Sans", sans-serif;
        color: #ffffff;
        font-size: 13px;
        font-weight: 700;
        line-height: 12px;
        padding-top: 7px; }
      .region-header .header-cart-block .my-cart-info + .commerce-order-handler-area-order-total {
        position: relative;
        top: -15px;
        left: 33px;
        font-size: 14px;
        font-family: 'A Garamond Pro', sans-serif;
        font-weight: bold;
        color: #bcbcbc;
        background: #232832;
        pointer-events: none; }
      .region-header .header-cart-block .my-cart-info:after {
        content: "$0.00";
        display: block;
        position: absolute;
        bottom: -7px;
        left: 33px;
        font-size: 14px;
        font-family: 'A Garamond Pro', sans-serif;
        font-weight: bold;
        color: #bcbcbc;
        pointer-events: none; }
  .region-header .header-search-block {
    margin-top: 31px; }
    .region-header .header-search-block .views-submit-button input {
      margin-top: 0; }

.header-search-block {
  float: right;
  margin-right: 35px; }
  .header-search-block .views-submit-button,
  .header-search-block #edit-search-api-views-fulltext-wrapper {
    padding: 0; }
  .header-search-block #edit-search-api-views-fulltext {
    background-color: #454b59;
    border-radius: 15px;
    border: 0;
    height: 30px;
    width: 186px;
    padding-left: 20px;
    padding-right: 36px;
    color: white;
    font-family: "Open Sans", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px; }
    .header-search-block #edit-search-api-views-fulltext::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: white; }
    .header-search-block #edit-search-api-views-fulltext::-moz-placeholder {
      /* Firefox 19+ */
      color: white; }
    .header-search-block #edit-search-api-views-fulltext:-ms-input-placeholder {
      /* IE 10+ */
      color: white; }
    .header-search-block #edit-search-api-views-fulltext:-moz-placeholder {
      /* Firefox 18- */
      color: white; }
  .header-search-block .views-exposed-widgets {
    position: relative; }
  .header-search-block .views-submit-button {
    display: block !important; }
    .header-search-block .views-submit-button input {
      position: absolute;
      right: 12px;
      top: 8px;
      height: 14px;
      width: 14px;
      margin: 0;
      padding: 0;
      border: 0;
      background: transparent url(../images/search.png) no-repeat center center;
      text-indent: -1000em;
      cursor: pointer; }

#block-views-product-block-slideshow-block {
  z-index: 998 !important; }

#menu {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #232832 url(../images/backmenu.jpg) center center no-repeat; }
  #menu .menu-bg-color {
    display: table;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.6); }
  #menu .menu-close {
    background: url(../images/close.png) center center no-repeat;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 39px;
    right: 39px;
    cursor: pointer; }

#menu-wrapper {
  display: table-cell;
  vertical-align: middle; }

#primary-menu-bar ul {
  padding: 0;
  margin: 0; }
  #primary-menu-bar ul li {
    padding: 0;
    margin: 0 0 15px 0;
    text-align: center; }
    #primary-menu-bar ul li a {
      color: #fff;
      font-family: "Gilroy", sans-serif;
      font-size: 25px;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 0.22em;
      transition: all 0.2s; }
      #primary-menu-bar ul li a:hover {
        color: #0ab29c;
        letter-spacing: 0.15em; }
      #primary-menu-bar ul li a:focus {
        border: 0;
        outline: 0; }

.menu-copyright {
  text-align: center;
  margin-top: 50px; }
  .menu-copyright p {
    color: #fff; }

.region-sidebar-first .sidebar-banner {
  display: block;
  width: 100%;
  margin: 0; }

.region-sidebar-first a:not(.tag-button) {
  display: block; }

.region-sidebar-first a:not(.tag-button),
.region-sidebar-first ul li,
.region-sidebar-first h3 {
  line-height: 2;
  color: #6e6e6e;
  transition: color 0.2s ease; }

.region-sidebar-first ul li:hover {
  color: #0ab29c; }
  .region-sidebar-first ul li:hover a:not(.tag-button) {
    color: #0ab29c; }

.region-sidebar-first a.active,
.region-sidebar-first ul li.active {
  color: #0ab29c; }

.region-sidebar-first .block {
  margin-bottom: 40px; }
  .region-sidebar-first .block h2.block-title {
    font-size: 24px;
    font-weight: 600;
    color: #424242;
    line-height: 25px;
    text-transform: uppercase;
    margin-bottom: 23px; }
  .region-sidebar-first .block h3 {
    font-size: 16px;
    font-weight: normal;
    font-family: "Open Sans", sans-serif;
    text-transform: capitalize;
    cursor: pointer; }
    .region-sidebar-first .block h3:hover {
      color: #0ab29c; }
  .region-sidebar-first .block .block-content ul {
    margin: 0;
    padding: 0; }
    .region-sidebar-first .block .block-content ul li {
      margin: 0;
      padding: 0; }
  .region-sidebar-first .block .block-content .facetapi-facetapi-links a {
    color: #606060;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px;
    text-transform: none; }

.region-sidebar-first .product-categories-block .item-list ul li {
  list-style: none; }

.region-sidebar-first .product-categories-block .item-list ul .leaf {
  list-style-type: disc;
  list-style-position: inside; }

.region-sidebar-first .invisible-block {
  display: none; }

.region-sidebar-first .special-offers-menu .block-content li:hover a {
  color: #e30000; }

.region-sidebar-first .special-offers-menu li.active a.active {
  color: #e30000; }

.region-sidebar-first .banner-catalog-1 {
  background-color: #fcebf1;
  overflow: hidden;
  width: 100%;
  max-width: 270px; }
  .region-sidebar-first .banner-catalog-1 h3 {
    font-family: "Gilroy", sans-serif;
    font-size: 18px;
    font-weight: bold;
    line-height: 18px;
    text-align: right;
    padding: 20px 20px 0 0;
    opacity: 0.5;
    margin: 0;
    text-transform: uppercase; }
    .region-sidebar-first .banner-catalog-1 h3:hover {
      color: #6e6e6e; }
  .region-sidebar-first .banner-catalog-1 .banner-img {
    text-align: center; }
    .region-sidebar-first .banner-catalog-1 .banner-img img {
      width: auto !important;
      height: auto !important; }
  .region-sidebar-first .banner-catalog-1 p {
    padding: 20px 0 35px 30px;
    margin: 0;
    color: #424242;
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 600;
    line-height: 25px;
    text-transform: uppercase; }

.region-sidebar-first .search-api-ranges-widget {
  padding-top: 8px;
  padding-bottom: 30px; }
  .region-sidebar-first .search-api-ranges-widget .text-range,
  .region-sidebar-first .search-api-ranges-widget .range-box,
  .region-sidebar-first .search-api-ranges-widget .form-submit {
    display: none; }

.region-sidebar-first .ui-slider-horizontal {
  background: #b1b1b1;
  height: 3px;
  border: none; }

.region-sidebar-first .ui-slider-horizontal .ui-slider-range {
  background: #424242; }

.region-sidebar-first .ui-slider-horizontal .ui-slider-handle {
  position: absolute;
  width: 3px;
  height: 17px;
  border: none;
  background: #424242;
  margin-left: 0;
  top: -7px; }
  .region-sidebar-first .ui-slider-horizontal .ui-slider-handle:first-of-type span {
    left: 0; }
  .region-sidebar-first .ui-slider-horizontal .ui-slider-handle:last-of-type span {
    right: 0; }
  .region-sidebar-first .ui-slider-horizontal .ui-slider-handle:last-of-type {
    transform: translateX(-3px); }

.region-sidebar-first .ui-slider-horizontal .ui-slider-handle span {
  font-family: "Gilroy", sans-serif;
  font-weight: bold;
  position: absolute;
  top: 100%;
  color: #424242;
  letter-spacing: 1px; }

.region-sidebar-first section.block-catalog-taxonomy-block {
  text-transform: capitalize; }

.region-sidebar-first .sale-products-block .sale-products-row {
  margin-bottom: 10px; }
  .region-sidebar-first .sale-products-block .sale-products-row .product-img {
    float: left;
    margin-right: 15px; }
  .region-sidebar-first .sale-products-block .sale-products-row .product-name {
    margin-bottom: 5px; }
  .region-sidebar-first .sale-products-block .sale-products-row .product-name a {
    color: #424242;
    font-family: "Gilroy", sans-serif;
    font-size: 20px;
    font-weight: bold;
    line-height: 1.3; }
  .region-sidebar-first .sale-products-block .sale-products-row .product-price,
  .region-sidebar-first .sale-products-block .sale-products-row .product-sale-price {
    display: inline-block;
    font-family: "A Garamond Pro";
    font-weight: 700;
    line-height: 28px; }
  .region-sidebar-first .sale-products-block .sale-products-row .product-price {
    margin-right: 8px;
    color: #b1b1b1;
    font-size: 18px;
    text-decoration: line-through; }
  .region-sidebar-first .sale-products-block .sale-products-row .product-sale-price {
    color: #0ab29c;
    font-size: 24px; }

.region-sidebar-first .block-catalog-taxonomy-block h3 + ul {
  overflow: hidden;
  height: 0; }
  .region-sidebar-first .block-catalog-taxonomy-block h3 + ul li {
    list-style-type: disc; }

.region-sidebar-first .block-catalog-taxonomy-block ul li {
  list-style-type: none;
  list-style-position: inside; }
  .region-sidebar-first .block-catalog-taxonomy-block ul li > div {
    display: inline-block; }

.region-sidebar-first .block-catalog-taxonomy-block .item-list h3.active {
  color: #0ab29c; }
  .region-sidebar-first .block-catalog-taxonomy-block .item-list h3.active + ul {
    height: auto; }

.region-sidebar-second .region-inner {
  padding-left: 64px;
  padding-right: 60px; }
  .region-sidebar-second .region-inner .lists-block h1 {
    margin-bottom: 23px; }
  .region-sidebar-second .region-inner .lists-block a {
    color: #606060;
    font-family: "Open Sans", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 32px; }
  .region-sidebar-second .region-inner .lists-block ol, .region-sidebar-second .region-inner .lists-block ul {
    margin: 0;
    padding: 0; }
    .region-sidebar-second .region-inner .lists-block ol li, .region-sidebar-second .region-inner .lists-block ul li {
      list-style-position: inside;
      color: #606060; }
  .region-sidebar-second .region-inner .lists-block ol {
    float: left;
    margin-right: 51px; }
  .region-sidebar-second .region-inner .lists-block ul li {
    list-style-type: none; }
  .region-sidebar-second .region-inner .lists-block ul li:before {
    font-family: 'Montserrat', sans-serif;
    font-weight: 400;
    color: #a0a0a0;
    content: "•";
    padding-right: 14px; }
  .region-sidebar-second .region-inner .popular-tags h1 {
    margin-bottom: 29px; }

html {
  position: relative;
  height: 100%;
  overflow-y: visible; }

body {
  font-family: "Open Sans", sans-serif;
  height: 100%;
  position: relative;
  overflow-x: hidden; }

div#columns *::selection {
  background: #0ab29c;
  color: white; }

#columns,
#footer-first #footer-columns,
#footer-second #footer-columns,
#header-wrapper,
#menu-wrapper,
#messages,
#partners,
#second-footer #footer-columns,
#slider,
#triptych-wrapper,
.breadcrumb {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto; }

/* =============================================================================
 *   Tables
 * ========================================================================== */
table {
  width: 100%;
  margin: 10px 0;
  padding: 0; }

table.sticky-header {
  z-index: 10; }

table,
tbody,
td,
th,
thead,
tr {
  border-color: #ccc; }

table tr th,
table tr th a,
table tr th a:hover {
  font-weight: bold;
  color: #606060;
  background: #fff; }

table,
td,
th {
  vertical-align: middle; }

caption,
td,
th {
  text-align: left; }

thead tr {
  font-weight: 700;
  background-color: #fff; }

td,
th {
  margin: 0;
  padding: 5px 7px;
  border-bottom: 0; }

tbody tr {
  border-top: 1px solid #ccc; }

tr.odd {
  background: whitesmoke;
  border-bottom: 1px solid #ccc; }

tr.even,
tr.info,
tr:nth-child(2n+2) {
  border-bottom: 0;
  background-color: #fff; }

tr.odd td.active {
  background-color: whitesmoke; }

tr.even td.active {
  background-color: #ebebeb; }

/*
 * 1. Remove most spacing between table cells
 */
table {
  border: 1px solid;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: inherit;
  font: 100%; }

/* =============================================================================
    Lists
   ========================================================================== */
/*
 * Addresses margins set differently in IE6/7
 */
dl,
menu,
ol,
ul {
  margin: 1em 0; }

dd {
  margin: 0 0 0 40px; }

/*
 * Addresses paddings set differently in IE6/7
 */
menu,
ol,
ul {
  padding: 0 0 0 40px; }

/*
 * Corrects list images handled incorrectly in IE7
 */
nav ol,
nav ul {
  list-style: none;
  list-style-image: none; }

h2,
h3,
.views-field {
  white-space: normal;
  word-wrap: break-word; }

input[type="text"],
input[type="password"] {
  box-sizing: border-box;
  display: block;
  height: 50px;
  padding: 0 20px;
  border: 2px solid #ccc;
  border-radius: 2px;
  line-height: 50px;
  color: #606060;
  transition: border-color 0.2s ease; }
  input[type="text"]:focus,
  input[type="password"]:focus {
    outline: none;
    border-color: #0ab29c; }

#partners .block-title {
  color: #202020;
  text-align: center;
  font-family: Quicksand;
  font-size: 24px;
  font-weight: 700;
  text-transform: uppercase; }

#partners .view-slideshow-partners {
  position: relative; }
  #partners .view-slideshow-partners .owl-buttons {
    position: absolute;
    top: 37%;
    width: 106%;
    margin: 0 0 0 -3%;
    padding: 0; }
    #partners .view-slideshow-partners .owl-buttons .owl-prev {
      float: left;
      width: 21px;
      height: 43px;
      margin: 0;
      padding: 0;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url("../images/left-arrow.png") no-repeat; }
    #partners .view-slideshow-partners .owl-buttons .owl-next {
      float: right;
      width: 21px;
      height: 43px;
      margin: 0;
      padding: 0;
      text-indent: 100%;
      white-space: nowrap;
      overflow: hidden;
      background: url("../images/right-arrow.png") no-repeat; }
  #partners .view-slideshow-partners .owl-pagination {
    display: none; }

#triptych {
  text-align: center;
  width: 100%; }
  #triptych .block-title {
    color: #202020;
    font-family: Quicksand;
    font-size: 24px;
    font-weight: 700;
    line-height: 18px;
    text-transform: uppercase; }
  #triptych .region {
    width: 33%;
    display: inline-block; }

#cboxMiddleLeft,
#cboxTopLeft,
#cboxTopCenter,
#cboxTopRight,
#cboxClose,
.commerce-add-to-cart .div #plus {
  display: none; }

#cboxContent {
  box-sizing: border-box;
  height: 480px !important;
  width: 100% !important;
  background: #fff; }

#cboxLoadedContent {
  border: none;
  overflow: auto;
  margin-bottom: 0; }

#cboxLoadedContent,
#cboxWrapper {
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box; }

#cboxWrapper {
  border-radius: 0 !important; }

#cboxOverlay {
  opacity: 0.55 !important; }

#cboxLoadingGraphic {
  background: none;
  height: 30px;
  width: 30px;
  border: 5px solid rgba(10, 178, 155, 0.45);
  border-top: 5px solid #0ab29b;
  border-radius: 30px;
  animation: rotate 1s infinite linear;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

#colorbox,
.page-product-item {
  position: relative; }
  #colorbox #messages,
  .page-product-item #messages {
    display: none; }
  #colorbox .cbox-container,
  .page-product-item .cbox-container {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 370px;
    padding: 30px 30px 40px;
    box-sizing: border-box; }
  #colorbox .product-title a,
  .page-product-item .product-title a {
    font-family: "Gilroy", sans-serif;
    color: #424242;
    font-size: 22px;
    font-weight: 800;
    text-transform: uppercase !important;
    text-decoration: none; }
  #colorbox .price-wrap,
  .page-product-item .price-wrap {
    padding-top: 6px;
    display: flex;
    align-items: center; }
  #colorbox .views-field-commerce-price,
  .page-product-item .views-field-commerce-price {
    font-family: "A Garamond Pro";
    color: #0ab29c;
    font-size: 28px;
    font-weight: 700;
    line-height: 18px;
    margin-right: 8px; }
  #colorbox .views-field-field-weight,
  .page-product-item .views-field-field-weight {
    color: #b1b1b1;
    font-family: "A Garamond Pro";
    font-size: 18px;
    font-weight: 700;
    line-height: 28px; }
  #colorbox .views-field-field-commerce-image,
  .page-product-item .views-field-field-commerce-image {
    position: absolute;
    width: 400px;
    height: 100%; }
    #colorbox .views-field-field-commerce-image img,
    .page-product-item .views-field-field-commerce-image img {
      display: block; }
  #colorbox .views-field-body,
  .page-product-item .views-field-body {
    padding-top: 16px; }
    #colorbox .views-field-body p,
    .page-product-item .views-field-body p {
      font-family: "A Garamond Pro";
      color: #424242;
      font-size: 20px;
      font-weight: 400;
      line-height: 23px;
      margin: 0; }
  #colorbox .main-operations,
  .page-product-item .main-operations {
    margin-top: auto; }
  #colorbox .form-item-quantity,
  .page-product-item .form-item-quantity {
    margin-top: -71.7px;
    margin-bottom: 30px; }
    #colorbox .form-item-quantity input,
    .page-product-item .form-item-quantity input {
      box-sizing: border-box;
      border-left: none;
      border-right: none;
      border-top: solid 3px #b1b1b1;
      border-bottom: solid 3px #b1b1b1;
      width: 72px;
      height: 40px;
      text-align: center;
      font-size: 24px;
      color: #424242;
      font-weight: 600;
      margin-left: 56px;
      border-radius: 0; }
  #colorbox .form-item-quantity label,
  #colorbox .label_price,
  .page-product-item .form-item-quantity label,
  .page-product-item .label_price {
    display: block;
    font-family: "Gilroy", sans-serif;
    color: #424242;
    font-size: 16px;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: 8px; }
  #colorbox .plus,
  #colorbox .minus,
  .page-product-item .plus,
  .page-product-item .minus {
    font-family: 'Gilroy', sans-serif;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    border: solid 3px #b1b1b1;
    color: #b1b1b1;
    width: 56px;
    height: 40px;
    line-height: 34px;
    text-align: center;
    cursor: pointer;
    font-size: 22px;
    font-weight: bold;
    user-select: none; }
  #colorbox .minus,
  .page-product-item .minus {
    border-radius: 20px 0 0 20px; }
  #colorbox .plus,
  .page-product-item .plus {
    left: 72px;
    border-radius: 0 20px 20px 0; }
  #colorbox .price_total,
  .page-product-item .price_total {
    float: right;
    margin-top: -32px; }
    #colorbox .price_total .label_price,
    .page-product-item .price_total .label_price {
      margin-bottom: 16px; }
  #colorbox .views-field-commerce-total,
  .page-product-item .views-field-commerce-total {
    width: 15%;
    margin-top: 2%;
    margin-left: 80%; }
  #colorbox .price,
  .page-product-item .price {
    font-family: "A Garamond Pro";
    color: #424242;
    font-size: 24px;
    font-weight: 700;
    line-height: 24px; }
  #colorbox .views-field-add-to-cart-form,
  .page-product-item .views-field-add-to-cart-form {
    width: 100%; }
  #colorbox .form-submit,
  .page-product-item .form-submit {
    width: 100%;
    display: inline-block;
    font-family: "Gilroy", sans-serif;
    font-size: 20px;
    font-weight: 800;
    line-height: 25px;
    text-transform: uppercase;
    padding: 15.5px 37px;
    border-radius: 30px;
    color: #fffefe;
    background-color: #0ab29c;
    border: none;
    letter-spacing: 1px; }
    #colorbox .form-submit:hover,
    .page-product-item .form-submit:hover {
      background-color: #2fbdab;
      color: #ffffff; }

#colorbox .views-field-body {
  position: relative;
  min-height: 150px;
  max-height: 150px;
  overflow: hidden; }
  #colorbox .views-field-body:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 50px;
    background-image: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.8) 30%, white 100%); }

.page-product-item .cbox-container {
  position: relative;
  width: 50%;
  padding: 30px 30px 40px 50px; }

.page-product-item .views-field-field-commerce-image {
  position: relative;
  float: left;
  width: 35%; }
  .page-product-item .views-field-field-commerce-image img {
    width: 100%; }

.page-product-item .views-field-body {
  margin-bottom: 100px; }

.page-product-item .form-submit {
  display: block;
  width: auto; }

.page-product-item .price_total {
  position: absolute;
  left: 260px; }

.front .page-product-item .form-submit {
  margin: 0 auto; }

.front .page-product-item .views-field-commerce-price {
  line-height: inherit; }

.contact-us-page.page-banner h3 {
  color: #ffffff; }

.contact-us-page.info-blocks {
  float: left;
  width: 50%;
  padding-right: 80px;
  box-sizing: border-box; }
  .contact-us-page.info-blocks:after {
    content: "";
    display: table;
    clear: both; }
  .contact-us-page.info-blocks h2 {
    margin-bottom: 24px; }
  .contact-us-page.info-blocks p {
    font: 400 20px/26px "A Garamond Pro";
    color: #424242;
    margin-bottom: 57px; }
  .contact-us-page.info-blocks .info-blocks-wrapper .info-block {
    display: inline-block;
    width: 50%;
    vertical-align: top;
    margin-bottom: 30px;
    float: left;
    padding-right: 20px;
    box-sizing: border-box; }
    .contact-us-page.info-blocks .info-blocks-wrapper .info-block p {
      font: 16px "Open Sans", sans-serif;
      margin: 0; }
    .contact-us-page.info-blocks .info-blocks-wrapper .info-block .block-icon {
      margin-bottom: 18px; }
    .contact-us-page.info-blocks .info-blocks-wrapper .info-block h3 {
      margin-bottom: 15px; }
  .contact-us-page.info-blocks .info-blocks-wrapper .info-block:nth-child(2n) {
    padding-right: 0; }

.contact-us-page.contact-form {
  float: right;
  background-color: white;
  box-shadow: 0 0 38.8px 1.2px rgba(0, 0, 0, 0.18);
  padding: 69px 85px;
  margin-bottom: 50px;
  width: 50%;
  box-sizing: border-box; }
  .contact-us-page.contact-form:after {
    content: "";
    display: table;
    clear: both; }
  .contact-us-page.contact-form h2 {
    text-align: center;
    color: #0ab29c; }
  .contact-us-page.contact-form p {
    text-align: center;
    color: #424242;
    font: 400 20px/26px "Minion Pro"; }
  .contact-us-page.contact-form input:not(.webform-submit) {
    border: none;
    border-bottom: 2px solid #b1b1b1;
    height: auto;
    padding: 0;
    padding-bottom: 15px;
    padding-top: 20px;
    font: 600 18px "Open Sans", sans-serif;
    color: #b1b1b1;
    background-color: transparent;
    width: 100%;
    transition: border-bottom-color 0.3s ease; }
  .contact-us-page.contact-form input:focus {
    color: #424042;
    outline: none;
    border-bottom-color: #0ab29c; }
  .contact-us-page.contact-form .form-actions {
    margin-top: 60px;
    text-align: center; }
    .contact-us-page.contact-form .form-actions .webform-submit {
      background-color: #0ab29c;
      border: none;
      border-radius: 30px; }

.front #slider {
  margin-top: 60px;
  padding: 0 30px;
  width: auto; }
  .front #slider .region-slider {
    position: relative; }
  .front #slider h3 {
    font-size: 55px; }
  .front #slider .home-page-slider .embedded-view {
    display: none; }
  .front #slider .home-page-slider .views-field-field-image {
    width: 100%;
    position: relative; }
    .front #slider .home-page-slider .views-field-field-image::after {
      content: "";
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background: rgba(0, 0, 0, 0.6); }
    .front #slider .home-page-slider .views-field-field-image img {
      display: block; }
  .front #slider .home-page-slider .with_block .views-field-body {
    position: absolute;
    left: 9%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999; }
    .front #slider .home-page-slider .with_block .views-field-body h3 {
      color: #fcfaf1;
      line-height: 1.09;
      text-transform: none;
      font-weight: 600;
      max-width: 600px; }
    .front #slider .home-page-slider .with_block .views-field-body p {
      margin-top: 30px;
      color: white;
      font-size: 18px;
      line-height: 1.66667;
      max-width: 500px; }
  .front #slider .home-page-slider .with_block .embedded-view {
    display: block;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 9%;
    background-color: #fff;
    text-align: center;
    padding: 35px 45px 40px; }
    .front #slider .home-page-slider .with_block .embedded-view .day-product-label {
      color: #c9c9c9;
      font-family: "Futura PT", sans-serif;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.79;
      text-transform: uppercase;
      max-width: 240px;
      letter-spacing: 0.1em; }
    .front #slider .home-page-slider .with_block .embedded-view .product-of-the-day {
      margin-top: 10px;
      max-width: 240px; }
      .front #slider .home-page-slider .with_block .embedded-view .product-of-the-day h2 {
        font-weight: 600;
        margin-bottom: 15px; }
      .front #slider .home-page-slider .with_block .embedded-view .product-of-the-day .day-product-description p {
        color: #424242;
        font-size: 15px;
        line-height: 20px;
        margin-bottom: 20px; }
      .front #slider .home-page-slider .with_block .embedded-view .product-of-the-day .day-product-img {
        height: 140px;
        -moz-border-radius: 70px;
        -webkit-border-radius: 70px;
        border-radius: 70px;
        display: inline-block;
        overflow: hidden; }
      .front #slider .home-page-slider .with_block .embedded-view .product-of-the-day .product-details-link a {
        display: inline-block;
        font-family: "Gilroy", sans-serif;
        text-transform: uppercase;
        -moz-border-radius: 28px;
        -webkit-border-radius: 28px;
        border-radius: 28px;
        color: #fffefe;
        background-color: #0ab29c;
        border: none;
        font-size: 16px;
        font-weight: 600;
        line-height: 1.6275;
        letter-spacing: 0.05em;
        padding: 8px 32px;
        margin-top: 25px; }
        .front #slider .home-page-slider .with_block .embedded-view .product-of-the-day .product-details-link a:hover {
          background-color: #2fbdab;
          color: #ffffff; }
  .front #slider .home-page-slider .center_content .views-field-body {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 999;
    text-align: center;
    width: 100%; }
    .front #slider .home-page-slider .center_content .views-field-body h3 {
      margin-bottom: 72px;
      color: white;
      font-weight: 300;
      line-height: 55px;
      text-transform: none;
      font-weight: 600;
      padding: 0 100px; }
    .front #slider .home-page-slider .center_content .views-field-body .icon-and-text {
      display: inline-block;
      margin-right: 45px;
      vertical-align: top;
      max-width: 220px; }
      .front #slider .home-page-slider .center_content .views-field-body .icon-and-text .slide-icon {
        margin-bottom: 30px; }
      .front #slider .home-page-slider .center_content .views-field-body .icon-and-text .slide-icon img {
        margin: 0 auto; }
      .front #slider .home-page-slider .center_content .views-field-body .icon-and-text .slide-text p {
        color: white;
        font-family: "A Garamond Pro";
        font-size: 20px; }
    .front #slider .home-page-slider .center_content .views-field-body .icon-and-text:last-child {
      margin-right: 0; }
  .front #slider .home-page-slider .left_content .views-field-body {
    position: absolute;
    z-index: 999;
    left: 9%;
    top: 50%;
    transform: translateY(-50%); }
    .front #slider .home-page-slider .left_content .views-field-body h3 {
      color: white;
      line-height: 60px;
      font-weight: 300;
      text-transform: none;
      margin-bottom: 40px;
      max-width: 820px; }
    .front #slider .home-page-slider .left_content .views-field-body .button-fill-green {
      font-weight: 600;
      line-height: 1; }
  .front #slider .slick-dots {
    width: 75px;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    bottom: 48px;
    transform: translateX(-50%); }
  .front #slider .slick-dots li {
    position: relative;
    width: 10px;
    height: 10px;
    margin: 0 auto;
    border-radius: 50%;
    text-indent: -9999px;
    background-color: #fff;
    opacity: 0.85;
    transition: all 0.2s ease; }
    .front #slider .slick-dots li:hover {
      opacity: 1; }
    .front #slider .slick-dots li:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 17px;
      height: 17px;
      background-color: transparent; }
  .front #slider .slick-dots li.slick-active {
    width: 12px;
    height: 12px;
    background-color: #0ab29c; }

.front .mobile-slide {
  position: relative;
  display: none;
  color: #fff;
  font-family: 'Gilroy', sans-serif;
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  letter-spacing: 2px;
  line-height: 1.2;
  overflow: hidden; }
  .front .mobile-slide__wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }
  .front .mobile-slide img {
    display: block; }
  .front .mobile-slide .title {
    display: block;
    margin-bottom: 20px; }
  .front .mobile-slide .button {
    letter-spacing: 1px;
    padding: 9.5px 37px;
    height: 44px;
    white-space: nowrap; }

.front #content .block {
  margin-bottom: 15px;
  clear: both; }

.front .home-banner {
  width: calc(50% - 15px);
  max-width: 50%; }
  .front .home-banner:first-child {
    margin-right: 30px; }

.product-categories-page .view-header {
  padding: 16px 35px;
  margin-bottom: 30px;
  background-color: #f5f5f5;
  overflow: hidden; }
  .product-categories-page .view-header .total-found {
    float: left; }
    .product-categories-page .view-header .total-found p {
      margin: 0;
      color: #424242;
      font-family: "Gilroy", sans-serif;
      font-size: 24px;
      font-weight: 800;
      text-transform: uppercase; }
  .product-categories-page .view-header .inline-display {
    float: right;
    width: 30px;
    height: 28px;
    background: url(../images/lines.png) center center no-repeat;
    margin-right: 15px; }
  .product-categories-page .view-header .inline-display.active {
    background: url(../images/lines-active.png) center center no-repeat; }
  .product-categories-page .view-header .grid-display {
    float: right;
    width: 44px;
    height: 28px;
    background: url(../images/grid.png) center center no-repeat; }
  .product-categories-page .view-header .grid-display.active {
    background: url(../images/grid-active.png) center center no-repeat; }
  .product-categories-page .view-header .inline-display:hover, .product-categories-page .view-header .grid-display:hover {
    cursor: pointer; }

.product-categories-page .item-list-pager {
  position: relative;
  margin: 50px 0 0 0;
  padding-top: 30px;
  display: block !important; }
  .product-categories-page .item-list-pager:after {
    content: "";
    display: block;
    position: absolute;
    bottom: 100%;
    width: 100%;
    height: 2px;
    background-color: #eee; }
  .product-categories-page .item-list-pager ul.pager {
    margin: 0;
    height: 28px; }
    .product-categories-page .item-list-pager ul.pager li {
      padding: 0;
      height: 28px;
      margin: 0 40px 0 0; }
    .product-categories-page .item-list-pager ul.pager li.pager-first, .product-categories-page .item-list-pager ul.pager li.pager-last {
      display: none; }
    .product-categories-page .item-list-pager ul.pager li.pager-previous {
      display: inline-block;
      margin: 0;
      float: left; }
      .product-categories-page .item-list-pager ul.pager li.pager-previous a {
        display: inline-block;
        width: 16px;
        height: 28px;
        background: url(../images/arrow-left.png); }
    .product-categories-page .item-list-pager ul.pager li.pager-next {
      display: inline-block;
      margin: 0;
      float: right; }
      .product-categories-page .item-list-pager ul.pager li.pager-next a {
        display: inline-block;
        width: 16px;
        height: 28px;
        background: url(../images/arrow-right.png); }
    .product-categories-page .item-list-pager ul.pager li.pager-item {
      margin-bottom: 0; }
      .product-categories-page .item-list-pager ul.pager li.pager-item a {
        color: #b1b1b1;
        font-family: "Gilroy", sans-serif;
        font-size: 24px;
        font-weight: 800; }
    .product-categories-page .item-list-pager ul.pager li.pager-current {
      margin-bottom: 0;
      color: #424242;
      font-family: "Gilroy", sans-serif;
      font-size: 24px;
      font-weight: 800; }

.product-categories-page .views-exposed-widgets {
  display: flex;
  justify-content: flex-end; }

.ajax-progress {
  display: none; }

body.colorbox--active {
  overflow: hidden; }

#page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column; }

#page {
  padding-top: 92px;
  background: #ffffff;
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: auto; }

#columns {
  width: auto;
  padding: 60px 30px 66px;
  overflow: hidden; }

/* Breadcrumbs */
.breadcrumb {
  position: relative;
  width: auto;
  margin-top: 20px;
  padding: 0 30px 20px;
  text-transform: capitalize; }
  .breadcrumb:after {
    content: "";
    display: block;
    position: absolute;
    top: 100%;
    left: 30px;
    right: 30px;
    height: 2px;
    background-color: #eee; }
  .breadcrumb span {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    font-weight: 700;
    line-height: 18px;
    color: #606060; }
    .breadcrumb span a {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      color: #606060; }
  .breadcrumb span.last a {
    color: #b1b1b1; }

/* Styles for banners at the top of the page */
.page-banner .banner {
  position: relative;
  width: 100%;
  line-height: 0; }
  .page-banner .banner img {
    width: 100%; }
  .page-banner .banner h3 {
    position: absolute;
    top: 50%;
    width: 100%;
    font-size: 50px;
    line-height: 100%;
    text-align: center;
    transform: translateY(-50%); }

.typography-page h3,
.about-page h3,
.how-it-works-page h3,
.contact-page h3 {
  color: white; }

/* Highlights */
.highlights {
  background-color: #0ab29c;
  color: white; }

/* Blockquote */
.blockquote {
  background: url(../images/quote.png) left top no-repeat;
  padding: 30px 0 0 30px; }
  .blockquote p {
    color: #353535;
    font-family: "A Garamond Pro";
    font-size: 22px;
    font-weight: 400;
    line-height: 32px; }
  .blockquote .author-info {
    font-family: "Gilroy", sans-serif;
    font-size: 24px;
    font-weight: 800; }
    .blockquote .author-info .name {
      color: #202020;
      text-transform: uppercase;
      margin-bottom: 15px; }
    .blockquote .author-info .additional-info {
      color: #0ab29c; }

/* Tables */
table {
  margin: 25px 0 25px 0;
  border: none; }
  table tr {
    font-family: "Open Sans", sans-serif;
    font-size: 15px;
    line-height: 25px; }
    table tr th, table tr td {
      padding: 20px 30px; }
    table tr th {
      color: #424242;
      font-weight: 700;
      text-transform: uppercase; }
    table tr td {
      color: #202020;
      font-weight: 400; }

/* Zebra style for tables */
table.zebra {
  border-collapse: initial; }
  table.zebra tr {
    border: none; }
  table.zebra tr.odd,
  table.zebra tr.odd th {
    background-color: #f5f5f5; }
  table.zebra tr.even {
    background-color: #ffffff; }

/* Horizontal borders style for tables */
table.row-border tr {
  border-bottom: 1px solid #d6d6d6; }

table.row-border thead tr {
  border-top: 1px solid #d6d6d6; }

/* style for Tags cloud */
.popular-tags {
  margin-bottom: 26px; }
  .popular-tags .view-content {
    display: flex;
    flex-wrap: wrap;
    text-align: left; }
    .popular-tags .view-content .tag-row {
      margin-bottom: 14px;
      margin-right: 10px; }
      .popular-tags .view-content .tag-row:last-child {
        margin-right: 0; }

/* Style for product display */
.products-blocks .product-row {
  position: relative; }
  .products-blocks .product-row .product-image {
    width: auto;
    height: 200px;
    background-color: #fafafa;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center; }
    .products-blocks .product-row .product-image img {
      margin-top: 2px; }
  .products-blocks .product-row .product-body-wrapper:after {
    content: "";
    display: table;
    clear: both; }
  .products-blocks .product-row .product-name {
    margin-top: 15px;
    margin-bottom: 5px; }
    .products-blocks .product-row .product-name a {
      font-family: "Gilroy", sans-serif;
      font-size: 20px;
      font-weight: bold;
      color: #424242; }
  .products-blocks .product-row .price {
    display: inline-block;
    float: left;
    font-family: "A Garamond Pro";
    font-weight: 700;
    line-height: 28px;
    margin-right: 8px; }
    .products-blocks .product-row .price:after {
      content: "";
      display: table;
      clear: both; }
    .products-blocks .product-row .price .product-price {
      margin-right: 8px;
      color: #b1b1b1;
      font-size: 18px;
      text-decoration: line-through;
      float: left; }
    .products-blocks .product-row .price .product-sale-price {
      color: #0ab29c;
      font-size: 24px;
      float: left; }
  .products-blocks .product-row .views-field-field-weight {
    float: left;
    color: #b1b1b1;
    font-family: "A Garamond Pro";
    font-size: 18px;
    font-weight: 700;
    line-height: 28px; }
  .products-blocks .product-row .product-body-text p {
    color: #424242;
    font-family: "A Garamond Pro";
    font-size: 18px;
    font-weight: 400;
    line-height: 24px; }
  .products-blocks .product-row .add-to-cart-form {
    position: absolute;
    background: transparent;
    left: 0;
    top: 0;
    width: 100%;
    height: 200px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    transition: all 0.2s;
    text-align: center; }
    .products-blocks .product-row .add-to-cart-form .add-to-cart-button {
      display: none;
      margin: 57px 0 38px 0; }
      .products-blocks .product-row .add-to-cart-form .add-to-cart-button .form-submit {
        border: 3px solid white;
        -moz-border-radius: 26px;
        -webkit-border-radius: 26px;
        border-radius: 26px;
        background: transparent;
        color: white;
        font: 800 20px/48px "Gilroy", sans-serif;
        text-transform: uppercase;
        padding: 0 27px; }
      .products-blocks .product-row .add-to-cart-form .add-to-cart-button .form-submit:hover {
        background: white;
        color: #424242; }
    .products-blocks .product-row .add-to-cart-form .view-ditails-link {
      display: none; }
      .products-blocks .product-row .add-to-cart-form .view-ditails-link a {
        color: white;
        font: 700 16px/20px "Open Sans", sans-serif; }
      .products-blocks .product-row .add-to-cart-form .view-ditails-link a:hover {
        opacity: 0.8; }
  .products-blocks .product-row .add-to-cart-form:hover {
    background: rgba(0, 0, 0, 0.45); }
    .products-blocks .product-row .add-to-cart-form:hover .add-to-cart-button {
      display: block; }
    .products-blocks .product-row .add-to-cart-form:hover .view-ditails-link {
      display: block; }

/* Style for display product in grid/inline mode */
/* Grid mode */
.grid-mode .view-content {
  display: flex;
  text-align: left;
  flex-wrap: wrap; }

.grid-mode .product-row {
  display: inline-block;
  margin-right: 3.5%;
  width: 31%;
  padding-bottom: 30px; }
  .grid-mode .product-row .product-body-text {
    display: none; }

.grid-mode .product-row:nth-of-type(3n) {
  margin-right: 0; }

/* Inline mode */
.inline-mode .product-row {
  overflow: hidden; }
  .inline-mode .product-row .product-image {
    float: left;
    width: 270px;
    margin-right: 25px;
    margin-bottom: 15px; }
  .inline-mode .product-row .add-to-cart-form {
    width: 270px; }
  .inline-mode .product-row .product-body-wrapper {
    padding: 0 22px 0 30px; }

.horizontal-blocks {
  text-align: center; }
  .horizontal-blocks .inline-block {
    display: inline-block; }

.open:after {
  transform: translateY(-50%) rotate(-90deg) !important; }

.sidebar-first .filter-button {
  display: none;
  position: relative;
  font-size: 24px;
  font-family: "Gilroy", sans-serif;
  font-weight: 800;
  padding: 8px 30px;
  margin: -60px -30px 0;
  width: calc(100% + 60px);
  text-transform: uppercase;
  background: #ededed;
  color: #424242;
  height: 60px;
  line-height: 60px; }
  .sidebar-first .filter-button:after {
    content: "";
    width: 26px;
    height: 24px;
    display: block;
    position: absolute;
    top: 50%;
    right: 30px;
    transform: translateY(-50%) rotate(90deg);
    background: url("../images/arrow-point-to-right.svg") no-repeat 50%;
    background-size: contain;
    transition: transform 0.3s ease; }

.sidebar-first .columns {
  display: flex;
  flex-wrap: wrap; }

.sidebar-first .content-column {
  float: none !important;
  width: calc(100% - 300px) !important;
  margin-left: auto; }
  .sidebar-first .content-column .content-inner {
    margin-right: 0 !important;
    margin-left: auto !important;
    max-width: 870px;
    width: 100%; }

.sidebar-second .filter-button {
  display: none;
  border: 2px solid;
  font-size: 20px;
  font-family: "Gilroy", sans-serif;
  padding: 8px;
  width: 100%;
  max-width: 280px;
  text-align: center;
  text-transform: uppercase;
  margin: 0 auto 30px; }
  .sidebar-second .filter-button:hover {
    background: #ededed; }

.sidebar-second .columns {
  display: flex;
  flex-direction: row-reverse;
  flex-wrap: wrap; }

.sidebar-second .content-column {
  float: none !important;
  width: calc(100% - 300px) !important;
  margin-right: auto; }
  .sidebar-second .content-column .content-inner {
    margin-right: auto !important;
    margin-left: 0 !important;
    max-width: 870px;
    width: 100%; }

.no-sidebars .filter-button {
  display: none; }

.region-sidebar-first {
  float: none !important;
  width: 100% !important;
  max-width: 300px;
  box-sizing: border-box;
  padding-right: 30px;
  margin-right: auto;
  margin-left: 0 !important; }

.region-sidebar-second {
  float: none !important;
  width: 100% !important;
  max-width: 270px;
  margin-right: 0;
  margin-left: auto !important; }
  .region-sidebar-second .region-inner {
    padding: 0; }

.best-deals-banners {
  position: relative;
  display: inline-block;
  overflow: hidden; }
  .best-deals-banners:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #000;
    opacity: 0.7;
    pointer-events: none;
    transition: opacity 0.3s ease; }
  .best-deals-banners:hover:after {
    opacity: 0.3; }
  .best-deals-banners__img img {
    display: block;
    width: 100%;
    height: auto; }
  .best-deals-banners__description {
    width: 100%;
    position: absolute;
    top: 50%;
    left: 0;
    padding: 5% 26px;
    transform: translateY(-50%);
    color: #fff;
    z-index: 1;
    box-sizing: border-box; }
  .best-deals-banners__title {
    margin-bottom: 23px; }
    .best-deals-banners__title a {
      font-family: "Gilroy", sans-serif;
      font-size: 36px;
      font-weight: 700;
      text-transform: capitalize; }
  .best-deals-banners__text {
    line-height: 1.2;
    margin: 0; }
    .best-deals-banners__text a {
      font-family: "A Garamond Pro", sans-serif;
      font-size: 26px; }
  .best-deals-banners a {
    color: #fff !important;
    line-height: 1.2 !important; }

.sidebar-banner {
  display: none;
  width: calc(100% + 60px);
  margin: 0 -30px -66px; }

.load-more {
  padding: 0 37px !important;
  height: 46px;
  display: none !important;
  margin: 50px auto 66px auto; }

.form-item-sort-by {
  position: relative;
  padding-top: 7px; }
  .form-item-sort-by label {
    font-family: 'Gilroy', sans-serif;
    font-size: 18px;
    font-weight: bold; }
  .form-item-sort-by a {
    position: absolute;
    top: -21px;
    left: 108%; }

.views-exposed-form .views-widget-sort-order {
  padding-right: 45px;
  padding-bottom: 10px; }
  .views-exposed-form .views-widget-sort-order label {
    display: none; }
  .views-exposed-form .views-widget-sort-order .form-type-bef-link {
    height: 18px; }
    .views-exposed-form .views-widget-sort-order .form-type-bef-link a {
      display: inline-block;
      line-height: 18px; }

.views-submit-button {
  display: none; }

.ajax-facets-controls-wrapper p {
  display: none; }

#content .block.h1-heading {
  margin-bottom: 54px; }
  #content .block.h1-heading h1 {
    margin-bottom: 18px; }
  #content .block.h1-heading p.order-information {
    margin-bottom: 18px;
    font-size: 24px;
    font-family: "A Garamond Pro"; }
  #content .block.h1-heading p {
    font-size: 16px;
    line-height: 26px; }

#content .block.h2-heading {
  margin-bottom: 56.5px; }
  #content .block.h2-heading h2 {
    margin-bottom: 22px; }
  #content .block.h2-heading p.order-information {
    margin-bottom: 14.5px;
    font-size: 20px;
    font-family: "A Garamond Pro";
    line-height: 26px; }
  #content .block.h2-heading p {
    font-size: 15px;
    line-height: 26px; }

#content .block.h3-heading {
  margin-bottom: 74px; }
  #content .block.h3-heading h3 {
    margin-bottom: 14px; }
  #content .block.h3-heading p.order-information {
    margin-bottom: 10px;
    font-size: 18px;
    font-family: "A Garamond Pro";
    line-height: 24px; }
  #content .block.h3-heading p {
    font-size: 14px;
    line-height: 24px; }

#content .block.breadcrumbs-example {
  margin-bottom: 100px; }
  #content .block.breadcrumbs-example h1 {
    margin-bottom: 28px; }
  #content .block.breadcrumbs-example .breadcrumb {
    display: inline-block;
    margin-right: 50px;
    width: auto; }

#content .block.highlights-example {
  margin-bottom: 55px; }
  #content .block.highlights-example h1 {
    margin-bottom: 20px;
    color: #2d2d2d; }

#content .block.blockquote-example {
  margin-bottom: 80px; }
  #content .block.blockquote-example h1 {
    margin-bottom: 23px;
    color: #2d2d2d; }

#content .block.tables-example {
  margin-bottom: 55px; }

#content .block.buttons-example h1 {
  margin-bottom: 19px; }

#content .block.buttons-example table {
  border-collapse: initial; }
  #content .block.buttons-example table td {
    padding: 0; }

.region-sidebar-second .region-inner .block {
  margin-bottom: 75px; }

.how-it-works-page.page-banner h3 {
  color: #ffffff; }

.how-it-works.steps-block {
  margin: 0 auto;
  margin-bottom: 70px;
  text-align: center; }
  .how-it-works.steps-block h2 {
    margin-bottom: 26px;
    line-height: 44px; }
  .how-it-works.steps-block .steps-wrapper {
    margin-right: -70px; }
    .how-it-works.steps-block .steps-wrapper .step, .how-it-works.steps-block .steps-wrapper .arrow {
      display: inline-block;
      vertical-align: top; }
    .how-it-works.steps-block .steps-wrapper .step {
      margin-right: 70px;
      margin-bottom: 30px; }
      .how-it-works.steps-block .steps-wrapper .step .step-img {
        margin-bottom: 37px; }
      .how-it-works.steps-block .steps-wrapper .step .step-title {
        margin-bottom: 25px; }
        .how-it-works.steps-block .steps-wrapper .step .step-title h3 {
          color: #606060;
          letter-spacing: 1.8px; }
      .how-it-works.steps-block .steps-wrapper .step .step-description p {
        color: #424242;
        font-family: "A Garamond Pro";
        font-size: 20px;
        line-height: 26px; }
    .how-it-works.steps-block .steps-wrapper .after-step-1 {
      margin-left: -75px;
      margin-right: -40px; }
    .how-it-works.steps-block .steps-wrapper .after-step-2 {
      margin-left: -40px;
      margin-right: -55px; }
    .how-it-works.steps-block .steps-wrapper .after-step-3 {
      margin-left: -55px;
      margin-right: -65px; }

.how-it-works-page.contact-us-banner {
  float: left;
  margin-right: 5%;
  width: 30%;
  background: rgba(153, 255, 204, 0.7);
  text-align: center;
  overflow: hidden;
  padding: 50px 0; }
  .how-it-works-page.contact-us-banner h2 {
    margin-bottom: 30px; }
  .how-it-works-page.contact-us-banner .contact-banner-img {
    margin-bottom: 40px; }

.how-it-works-page.frequently-asked-questions {
  width: 55%;
  padding: 50px 5%;
  background-color: #f5f5f5;
  float: left; }
  .how-it-works-page.frequently-asked-questions h2 {
    text-align: center;
    margin-bottom: 30px; }
  .how-it-works-page.frequently-asked-questions .views-row {
    position: relative;
    padding: 23px 15px 20px 19px;
    border-top: 2px solid #b1b1b1;
    overflow: hidden; }
    .how-it-works-page.frequently-asked-questions .views-row .question-answer-wrapper {
      float: left;
      margin-right: 26px; }
      .how-it-works-page.frequently-asked-questions .views-row .question-answer-wrapper .question {
        color: #202020;
        font-family: "Open Sans", sans-serif;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px; }
    .how-it-works-page.frequently-asked-questions .views-row .show-hide-button {
      position: absolute;
      top: 23px;
      right: 15px;
      width: 20px;
      height: 20px;
      cursor: pointer; }
  .how-it-works-page.frequently-asked-questions .views-row:last-child {
    border-bottom: 2px solid #b1b1b1; }
  .how-it-works-page.frequently-asked-questions .views-row.expanded .question {
    margin-bottom: 25px; }
    .how-it-works-page.frequently-asked-questions .views-row.expanded .question span {
      font-weight: 700; }
  .how-it-works-page.frequently-asked-questions .views-row.expanded .show-hide-button {
    background: url(../images/hide-button.png) center center no-repeat; }
  .how-it-works-page.frequently-asked-questions .views-row.collapsed .answer {
    display: none; }
  .how-it-works-page.frequently-asked-questions .views-row.collapsed .show-hide-button {
    background: url(../images/show-button.png) center center no-repeat; }

.about-page.page-banner h3 {
  color: #ffffff; }

.about-page.about-facts {
  margin-bottom: 54px; }
  .about-page.about-facts h2 {
    text-align: center;
    margin-bottom: 37px; }
  .about-page.about-facts .horizontal-blocks {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    margin-right: -26px; }
    .about-page.about-facts .horizontal-blocks .inline-block {
      width: 269px;
      margin-right: 26px; }

.about-page.our-team {
  margin-bottom: 79px;
  text-align: center; }
  .about-page.our-team h2 {
    margin-bottom: 40px; }
  .about-page.our-team .horizontal-blocks {
    margin-right: -25px; }
  .about-page.our-team .inline-block {
    box-sizing: border-box;
    padding-bottom: 10px;
    padding-right: 25px; }
  .about-page.our-team .inline-block-inner {
    position: relative;
    height: 370px;
    width: 370px; }
    .about-page.our-team .inline-block-inner:hover .inline-block-description {
      opacity: 1; }
      .about-page.our-team .inline-block-inner:hover .inline-block-description .user-name,
      .about-page.our-team .inline-block-inner:hover .inline-block-description .user-position {
        left: 0; }
    .about-page.our-team .inline-block-inner .inline-block-description {
      background: rgba(25, 25, 25, 0.5);
      position: absolute;
      padding: 34px;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      text-align: left;
      transition: all 0.3s ease;
      opacity: 0;
      display: flex;
      align-items: flex-end;
      box-sizing: border-box; }
    .about-page.our-team .inline-block-inner .inline-block-img {
      font-size: 0; }
    .about-page.our-team .inline-block-inner .user-info-wrapper {
      margin-top: auto; }
      .about-page.our-team .inline-block-inner .user-info-wrapper .user-name {
        color: white;
        font-family: "Gilroy", sans-serif;
        font-size: 24px;
        font-weight: 800;
        line-height: 26px;
        text-transform: uppercase;
        margin-bottom: 8px;
        transition: all 0.4s ease;
        position: relative;
        left: 20px; }
      .about-page.our-team .inline-block-inner .user-info-wrapper .user-position {
        color: #0ab29c;
        font-family: "Gilroy", sans-serif;
        font-size: 24px;
        font-weight: 800;
        line-height: 26px;
        transition: all 0.4s ease 0.05s;
        position: relative;
        left: 20px; }
  .about-page.our-team .team-member:last-child {
    margin-right: 0; }
  .about-page.our-team .team-member:hover .user-info-wrapper {
    display: block; }
  .about-page.our-team .view-content:after {
    width: 100%;
    height: 0;
    visibility: hidden;
    overflow: hidden;
    content: '';
    display: inline-block; }

.about-page.our-clients {
  text-align: center; }
  .about-page.our-clients h2 {
    margin-bottom: 54px; }
  .about-page.our-clients .owl-prev,
  .about-page.our-clients .owl-next {
    width: 20px;
    height: 29px;
    top: 50px; }
  .about-page.our-clients .owl-prev {
    position: absolute;
    left: -15px;
    background: url(../images/arrow-left.png) center center no-repeat !important; }
  .about-page.our-clients .owl-next {
    position: absolute;
    right: -15px;
    background: url("../images/arrow-right.png") center center no-repeat !important; }
  .about-page.our-clients .disabled {
    display: none !important; }
  .about-page.our-clients .view-content {
    height: auto !important; }

#subscribe {
  background-color: #f5f5f5;
  text-align: center;
  padding: 50px;
  overflow: hidden; }
  #subscribe label {
    display: none; }
  #subscribe .block-simplenews {
    max-width: 1170px;
    margin: 0 auto; }
  #subscribe .form-item {
    display: inline-block;
    margin-right: 30px;
    width: 27%; }
  #subscribe .form-text {
    border: 2px solid #b1b1b1;
    padding: 10px 15px;
    font: 600 18px "Open Sans", sans-serif;
    color: #b1b1b1;
    max-width: 100%;
    width: 100%;
    transition: border 0.3s ease;
    outline: none;
    box-sizing: border-box;
    background-color: transparent; }
    #subscribe .form-text:focus {
      border-color: #0ab29c; }
  #subscribe .form-submit {
    border: none;
    color: #fffefe;
    background-color: #0ab29c;
    line-height: 46px;
    padding: 0 37px; }
    #subscribe .form-submit:hover {
      color: #fff;
      background-color: #2fbdab; }
  #subscribe p {
    color: #424242;
    font: 400 20px/26px "Minion Pro";
    margin: 10px 0 20px; }

.page-cart table {
  border-collapse: initial; }
  .page-cart table thead th {
    background: #f5f5f5;
    text-align: center; }
    .page-cart table thead th:first-child {
      text-align: start; }
  .page-cart table tbody tr {
    padding: 0; }
    .page-cart table tbody tr td {
      padding: 15px 30px 15px 30px;
      background: #ffffff;
      text-align: center; }
      .page-cart table tbody tr td:before {
        content: attr(data-th);
        display: none;
        text-transform: uppercase;
        font-family: "Gilroy", sans-serif;
        color: #424242;
        font-weight: 800;
        font-size: 18px; }
      .page-cart table tbody tr td:first-child {
        text-align: start;
        padding-left: 0;
        display: flex;
        align-items: center; }
        .page-cart table tbody tr td:first-child:before {
          display: none; }
      .page-cart table tbody tr td:last-child:before {
        display: none; }
    .page-cart table tbody tr td.views-field-nothing img {
      vertical-align: middle;
      max-width: 170px;
      width: 170px; }
    .page-cart table tbody tr td.views-field-nothing .product-title {
      display: inline-block;
      padding-left: 30px;
      vertical-align: middle;
      box-sizing: border-box;
      max-width: 300px; }
      .page-cart table tbody tr td.views-field-nothing .product-title a {
        font: bold 20px/20px "Gilroy", sans-serif;
        color: #424242; }
    .page-cart table tbody tr td.views-field-nothing .product-weight {
      color: #b1b1b1;
      font-family: "A Garamond Pro", sans-serif;
      font-size: 18px;
      font-weight: 700;
      line-height: 28px; }
    .page-cart table tbody tr td.price {
      color: #424242;
      font: 700 24px/24px "A Garamond Pro"; }
  .page-cart table tbody tr:first-child td {
    padding-top: 30px; }
  .page-cart table tbody tr:last-child td {
    padding-bottom: 40px; }

.page-cart .view-header {
  font-family: "Gilroy", sans-serif;
  color: #424242;
  font-size: 36px;
  font-weight: 800;
  line-height: 25px;
  text-align: left; }

.page-cart .line-item-summary {
  background: #f5f5f5;
  margin-bottom: 0; }
  .page-cart .line-item-summary .line-item-total {
    color: #424242;
    font-family: "A Garamond Pro";
    font-size: 24px;
    line-height: 24px;
    font-weight: 700;
    text-transform: uppercase;
    padding: 18px 36px 18px 0; }

.page-cart .services {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 40px;
  margin-right: -30px; }
  .page-cart .services > * {
    width: calc(100% / 4);
    padding-right: 30px;
    padding-bottom: 25px;
    box-sizing: border-box; }
  .page-cart .services .call_services,
  .page-cart .services .free_delivery {
    display: flex;
    align-items: center; }
    .page-cart .services .call_services p,
    .page-cart .services .free_delivery p {
      font-size: 16px;
      color: #424242;
      margin: 0;
      padding-left: 20px; }
  .page-cart .services .button {
    width: 100%;
    box-sizing: border-box;
    padding: 13px 0;
    height: 56px; }

#views-form-commerce-cart-form-default .commerce-quantity-plusminus-link a {
  position: absolute;
  width: 13px;
  height: 13px;
  margin-top: 1px;
  line-height: 17px;
  color: #b1b1b1;
  border: solid 1px; }

#views-form-commerce-cart-form-default .views-field-edit-quantity {
  white-space: nowrap;
  width: 144px;
  min-width: 144px; }

#views-form-commerce-cart-form-default .form-text {
  box-sizing: border-box;
  border-left: none;
  border-right: none;
  border-top: solid 2px #b1b1b1;
  border-bottom: solid 2px #b1b1b1;
  width: calc(100% / 3);
  height: 40px;
  text-align: center;
  float: left;
  font-size: 24px;
  color: #424242;
  font-weight: 600;
  padding: 0;
  border-radius: 0; }

#views-form-commerce-cart-form-default .minus,
#views-form-commerce-cart-form-default .plus {
  box-sizing: border-box;
  border: solid 2px #b1b1b1;
  color: #b1b1b1;
  width: calc(100% / 3);
  height: 40px;
  line-height: 35px;
  text-align: center;
  cursor: pointer;
  font-size: 20px;
  font-weight: 800;
  float: left;
  user-select: none; }

#views-form-commerce-cart-form-default .minus {
  border-radius: 20px 0 0 20px; }

#views-form-commerce-cart-form-default .plus {
  border-radius: 0 20px 20px 0; }

#views-form-commerce-cart-form-default #edit-submit,
#views-form-commerce-cart-form-default #edit-checkout {
  display: none; }

#views-form-commerce-cart-form-default #edit-checkout {
  border: 0; }

#views-form-commerce-cart-form-default .back-button {
  position: absolute;
  display: inline-block;
  right: 190px;
  margin-top: 1em; }

#views-form-commerce-cart-form-default .commerce-line-item-actions {
  float: right;
  display: inline-block; }

#views-form-commerce-cart-form-default #delete {
  cursor: pointer; }

#content h2 {
  margin-bottom: 40px; }

.block-colorized-gmap h2,
.gm-style-cc {
  display: none !important; }

.colorized-gmap-common {
  width: 100%; }

.gmnoprint {
  left: 0px !important;
  bottom: 83px !important; }

table,
tbody,
td,
th,
thead,
tr {
  border: none; }

table tr th,
table tr th a,
table tr th a:hover {
  background: #f5f5f5; }

tbody tr {
  border: none; }

tr.odd {
  background: #fff;
  border: none; }

.page-checkout {
  font-family: 'Open Sans', sans-serif;
  color: #606060; }
  .page-checkout table.checkout-review {
    margin: 0; }
    .page-checkout table.checkout-review td,
    .page-checkout table.checkout-review tr {
      margin: 0;
      padding: 0; }
  .page-checkout fieldset {
    border: none;
    padding: 0;
    margin: 0; }
  .page-checkout label {
    text-transform: uppercase;
    margin-bottom: 10px; }
  .page-checkout h3 {
    margin-bottom: 30px; }
  .page-checkout .sub-title {
    color: #424242;
    font-size: 16px;
    font-weight: 700;
    display: block;
    margin-bottom: 20px; }
  .page-checkout .description {
    margin: 0;
    width: 100%;
    padding-top: 5px; }
  .page-checkout .checkout-help {
    display: none; }
  .page-checkout .columns .form-item {
    float: none;
    margin-right: 0; }
  .page-checkout .columns .commerce_payment #payment-details {
    clear: both; }
  .page-checkout .columns .commerce_payment .form-type-radio {
    position: relative;
    width: 145px;
    height: 115px;
    float: left;
    margin-right: 30px; }
    .page-checkout .columns .commerce_payment .form-type-radio input[type="radio"] {
      display: none; }
  .page-checkout .columns .commerce_payment input[type="radio"] ~ label {
    display: block;
    width: 100%;
    height: 100%;
    border: 3px solid #f5f5f5;
    border-radius: 5px;
    background-color: #f5f5f5;
    background-repeat: no-repeat;
    background-position: 50%;
    text-indent: -9999px; }
    .page-checkout .columns .commerce_payment input[type="radio"] ~ label * {
      display: none; }
  .page-checkout .columns .commerce_payment input[type="radio"]:checked ~ label {
    border-color: #ccc;
    background-color: transparent; }
  .page-checkout .columns .commerce_payment input[type="radio"]#edit-commerce-payment-payment-method-paypal-wppcommerce-payment-paypal-wpp ~ label {
    background-image: url("../images/credit-card--default.png"); }
  .page-checkout .columns .commerce_payment input[type="radio"]#edit-commerce-payment-payment-method-paypal-wppcommerce-payment-paypal-wpp:checked ~ label {
    background-image: url("../images/credit-card--active.png"); }
  .page-checkout .columns .commerce_payment input[type="radio"]#edit-commerce-payment-payment-method-paypal-wpscommerce-payment-paypal-wps ~ label {
    background-image: url("../images/paypal--default.png"); }
  .page-checkout .columns .commerce_payment input[type="radio"]#edit-commerce-payment-payment-method-paypal-wpscommerce-payment-paypal-wps:checked ~ label {
    background-image: url("../images/paypal--active.png"); }
  .page-checkout .columns .commerce-credit-card-expiration {
    display: inline-flex;
    align-items: flex-end;
    width: calc((100% / 3) * 2);
    white-space: nowrap; }
    .page-checkout .columns .commerce-credit-card-expiration .form-type-select {
      width: calc(100% / 2);
      margin-right: 15px; }
    .page-checkout .columns .commerce-credit-card-expiration .commerce-month-year-divider {
      display: none; }
    .page-checkout .columns .commerce-credit-card-expiration + .form-type-textfield {
      float: left;
      width: calc(100% / 3);
      padding-right: 15px;
      box-sizing: border-box;
      white-space: nowrap; }
  .page-checkout .columns .form-item-customer-profile-shipping-commerce-customer-address-und-0-administrative-area,
  .page-checkout .columns .form-item-customer-profile-billing-commerce-customer-address-und-0-administrative-area {
    margin-right: 30px;
    width: calc(50% - 30px);
    float: left; }
    .page-checkout .columns .form-item-customer-profile-shipping-commerce-customer-address-und-0-administrative-area + .form-type-textfield,
    .page-checkout .columns .form-item-customer-profile-billing-commerce-customer-address-und-0-administrative-area + .form-type-textfield {
      display: inline-block;
      width: 50%; }
  .page-checkout .columns .form-type-select {
    box-sizing: border-box;
    position: relative; }
    .page-checkout .columns .form-type-select:last-child {
      margin-right: 0; }
    .page-checkout .columns .form-type-select select {
      appearance: none;
      width: 100%;
      height: 50px;
      padding: 0 20px;
      background-color: #eee;
      color: #606060;
      font-weight: 600;
      border: 2px solid transparent;
      border-radius: 2px;
      transition: border-color 0.2s ease; }
      .page-checkout .columns .form-type-select select:focus {
        outline: none;
        border-color: #0ab29c; }
    .page-checkout .columns .form-type-select select::-ms-expand {
      display: none; }
    .page-checkout .columns .form-type-select:after {
      content: "";
      box-sizing: border-box;
      display: block;
      position: absolute;
      right: 12px;
      bottom: 22px;
      width: 10px;
      height: 10px;
      border: 2px solid #424242;
      border-right: none;
      border-top: none;
      transform: rotate(-45deg);
      pointer-events: none; }
  .page-checkout .columns input[type="text"],
  .page-checkout .columns input[type="password"] {
    width: 100%; }
  .page-checkout .columns input[type="text"][id*="edit-commerce-payment-payment-details-credit-card-number"] {
    background: url("../images/credit-card--text-field.png") no-repeat 96% 50%;
    padding-right: 60px; }
  .page-checkout .columns input[type="checkbox"],
  .page-checkout .columns input[type="radio"] {
    appearance: none;
    position: relative;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    transition: border-color 0.2s ease;
    cursor: pointer; }
    .page-checkout .columns input[type="checkbox"]:focus,
    .page-checkout .columns input[type="radio"]:focus {
      outline: none; }
    .page-checkout .columns input[type="checkbox"]:active,
    .page-checkout .columns input[type="radio"]:active {
      border-color: #b1b1b1; }
  .page-checkout .columns input[type="checkbox"] {
    border: 1px solid #4a4a4a; }
  .page-checkout .columns input[type="checkbox"]:checked:after {
    content: "";
    display: block;
    position: absolute;
    left: 50%;
    top: 45%;
    width: 6px;
    height: 3px;
    border: 2px solid #4a4a4a;
    border-right: none;
    border-top: none;
    transform: translate(-50%, -50%) rotate(-45deg); }
  .page-checkout .columns input[type="radio"] {
    border: 2px solid #4a4a4a;
    border-radius: 50%; }
  .page-checkout .columns input[type="radio"]:checked:after {
    content: "";
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 8px;
    background-color: #0ab29c;
    border-radius: 50%; }
  .page-checkout .fieldset-legend {
    font-family: 'Gilroy', sans-serif;
    font-size: 24px;
    font-weight: 800;
    color: #424242;
    text-transform: uppercase; }
  .page-checkout #edit-cancel {
    border: none; }
  .page-checkout #edit-account-form-select-login-continue,
  .page-checkout #edit-account-form-select-register-continue {
    display: inline-block;
    font-family: "Gilroy", sans-serif;
    font-size: 18px;
    font-weight: 800;
    line-height: 25px;
    text-transform: uppercase;
    padding: 15.5px 37px;
    -moz-border-radius: 28px;
    -webkit-border-radius: 28px;
    border-radius: 28px;
    color: #fffefe;
    background-color: #0ab29c;
    border: none; }
    .page-checkout #edit-account-form-select-login-continue:hover,
    .page-checkout #edit-account-form-select-register-continue:hover {
      background-color: #2fbdab;
      color: #ffffff; }
  .page-checkout .content-wrapper {
    margin-bottom: 0 !important; }
    .page-checkout .content-wrapper:after {
      content: "";
      display: table;
      clear: both; }
  .page-checkout #commerce-checkout-form-checkout > div,
  .page-checkout #commerce-checkout-form-shipping > div,
  .page-checkout #commerce-checkout-form-review > div,
  .page-checkout #commerce-checkout-form-login > div {
    display: flex;
    flex-wrap: wrap;
    max-width: 970px;
    margin: 0 auto; }
  .page-checkout .commerce_user_profile_pane,
  .page-checkout .customer_profile_shipping,
  .page-checkout #customer-profile-billing-ajax-wrapper,
  .page-checkout .cvp-billing_rewiew_1-commerce_checkout_pane_1,
  .page-checkout #commerce-shipping-service-ajax-wrapper,
  .page-checkout .checkout_review,
  .page-checkout .commerce_payment {
    width: calc(100% / 2);
    box-sizing: border-box; }
  .page-checkout .commerce_user_profile_pane,
  .page-checkout .cvp-billing_rewiew_1-commerce_checkout_pane_1,
  .page-checkout .checkout_review {
    padding-right: 50px; }
  .page-checkout .customer_profile_shipping,
  .page-checkout #commerce-shipping-service-ajax-wrapper,
  .page-checkout .commerce_payment {
    padding-left: 50px; }
  .page-checkout #customer-profile-billing-ajax-wrapper {
    margin-left: auto;
    width: calc((100% / 2) - 50px); }
    .page-checkout #customer-profile-billing-ajax-wrapper .form-item-customer-profile-billing-commerce-customer-profile-copy {
      display: flex;
      align-items: center; }
    .page-checkout #customer-profile-billing-ajax-wrapper .fieldset-legend {
      display: none; }
    .page-checkout #customer-profile-billing-ajax-wrapper label.option {
      text-transform: capitalize;
      margin-bottom: 0;
      font-weight: 600;
      font-size: 15px;
      color: #424242; }
    .page-checkout #customer-profile-billing-ajax-wrapper .form-checkbox {
      margin-right: 10px;
      flex: 0 0 auto; }
  .page-checkout #edit-commerce-payment-payment-method:after {
    content: "";
    display: table;
    clear: both; }
  .page-checkout .your-data,
  .page-checkout .your-order {
    background-color: #f5f5f5;
    color: #424242;
    padding: 30px;
    margin-bottom: 30px; }
    .page-checkout .your-data:after,
    .page-checkout .your-order:after {
      content: "";
      display: table;
      clear: both; }
    .page-checkout .your-data a,
    .page-checkout .your-order a {
      color: #424242; }
  .page-checkout .your-data {
    font-size: 14px; }
    .page-checkout .your-data .views-fieldset {
      margin-bottom: 30px; }
    .page-checkout .your-data .billing-info,
    .page-checkout .your-data .shipping-info {
      box-sizing: border-box;
      width: 50%;
      float: left; }
    .page-checkout .your-data .billing-info {
      padding-right: 20px; }
  .page-checkout .your-order .shipping-price,
  .page-checkout .your-order .total-price {
    float: left; }
  .page-checkout .your-order .shipping-price {
    margin-right: 38px; }
    .page-checkout .your-order .shipping-price span.views-label {
      font-weight: 700; }
  .page-checkout .your-order table {
    margin: 0; }
    .page-checkout .your-order table tr,
    .page-checkout .your-order table td {
      padding: 0;
      display: block;
      color: #424242;
      font-size: 16px; }
    .page-checkout .your-order table tr {
      float: left;
      background: transparent;
      padding-right: 38px; }
    .page-checkout .your-order table td:first-child {
      font-weight: 700; }
  .page-checkout .form-type-radio {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #424242; }
    .page-checkout .form-type-radio label {
      font-family: 'Gilroy', sans-serif;
      font-size: 20px;
      font-weight: 700;
      margin-bottom: 0;
      text-transform: capitalize; }
    .page-checkout .form-type-radio .form-radio {
      margin-right: 10px;
      flex: 0 0 auto; }
  .page-checkout .checkout-buttons {
    width: 100%; }

.steps-of-checkout {
  font-family: 'Gilroy', sans-serif;
  font-size: 20px;
  font-weight: 800;
  display: flex;
  justify-content: center;
  color: #424242;
  text-transform: uppercase;
  margin-bottom: 67px; }

.step {
  margin-right: 50px;
  display: flex;
  align-items: center; }
  .step:last-child {
    margin-right: 0; }
  .step__number {
    display: block;
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    border: 3px solid #0ab29c;
    border-radius: 50%;
    text-align: center;
    line-height: 45px;
    margin-right: 15px;
    color: #0ab29c; }

.page-checkout-checkout .step-1,
.page-checkout-shipping .step-2,
.page-checkout-review .step-3 {
  color: #0ab29c; }
  .page-checkout-checkout .step-1 .step__number,
  .page-checkout-shipping .step-2 .step__number,
  .page-checkout-review .step-3 .step__number {
    color: #fff;
    background-color: #0ab29c; }

.checkout-buttons {
  color: #919191; }
  .checkout-buttons .fieldset-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding-top: 60px; }
  .checkout-buttons .continue-wrap {
    position: relative;
    margin-bottom: 50px;
    min-width: 250px; }
    .checkout-buttons .continue-wrap:before, .checkout-buttons .continue-wrap:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 430px;
      height: 2px;
      background-color: #eee; }
    .checkout-buttons .continue-wrap:before {
      right: calc(100% + 30px); }
    .checkout-buttons .continue-wrap:after {
      left: calc(100% + 30px); }
  .checkout-buttons .button-operator {
    font-family: 'Gilroy', sans-serif;
    margin-bottom: 15px;
    font-size: 20px;
    font-style: italic;
    letter-spacing: 1.6px;
    color: #919191; }
  .checkout-buttons input.checkout-continue {
    width: 100%;
    letter-spacing: 1.8px; }
  .checkout-buttons input.checkout-back {
    text-transform: capitalize;
    position: relative;
    background: url("../images/left-arrow.svg") no-repeat 31px 50% #424242;
    background-size: 22px;
    padding-left: 67px; }
    .checkout-buttons input.checkout-back:hover {
      background: url("../images/left-arrow.svg") no-repeat 31px 50% #5e5e5e;
      background-size: 22px; }
    .checkout-buttons input.checkout-back:focus {
      background: url("../images/left-arrow.svg") no-repeat 31px 50% #5e5e5e;
      background-size: 22px;
      color: #fffefe;
      text-decoration: none; }

#commerce-checkout-form-login .checkout-buttons .fieldset-wrapper {
  padding-top: 20px;
  align-items: flex-start; }

#commerce-checkout-form-login .checkout-buttons .continue-wrap,
#commerce-checkout-form-login .checkout-buttons .button-operator {
  display: none; }

.checkout-completion-message {
  text-align: center;
  padding: 90px 0 60px; }
  .checkout-completion-message p {
    font-family: 'A Garamond Pro', sans-serif;
    font-size: 24px;
    line-height: 32px;
    color: #424242;
    margin-bottom: 120px; }
  .checkout-completion-message .title {
    font-family: 'Gilroy', sans-serif;
    font-weight: bold;
    font-size: 50px;
    letter-spacing: 5px;
    margin-bottom: 41px; }
  .checkout-completion-message .button-border-green {
    position: relative;
    box-sizing: border-box; }
    .checkout-completion-message .button-border-green:before, .checkout-completion-message .button-border-green:after {
      content: "";
      display: block;
      position: absolute;
      top: 50%;
      width: 430px;
      height: 2px;
      background-color: #eee; }
    .checkout-completion-message .button-border-green:before {
      right: calc(100% + 30px); }
    .checkout-completion-message .button-border-green:after {
      left: calc(100% + 30px); }
