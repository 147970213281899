.front {

  #slider {
    //letter-spacing: 0.8px;
    margin-top: 60px;
    padding: 0 30px;
    width: auto;
    .region-slider {
      position: relative;
    }

    h3 {
      font-size: 55px;
    }

    .home-page-slider {
      .embedded-view {
        display: none;
      }
      // .views-slideshow-cycle-main-frame {
      //   width: auto !important;
      //   height: auto !important;
      //   max-width: 1170px;
      //   max-height: 640px;
      //   overflow: hidden;
      // }
      // .views-slideshow-cycle-main-frame-row {
      //   width: auto !important;
      //   height: auto !important;
      //   position: static !important;
      //   overflow: hidden;
      // }
      .views-field-field-image {
        width: 100%;
        position: relative;
        &::after {
          content: "";
          display: block;
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;
          background: rgba(0,0,0,0.6);
        }
        img {
          display: block;
        }
      }
      .with_block {
        .views-field-body {
          position: absolute;
          left: 9%;
          top: 50%;
          transform: translateY(-50%);
          z-index: 999;
          h3 {
            color: #fcfaf1;
            line-height: 1.09;
            text-transform: none;
            font-weight: 600;
            max-width: 600px;
          }
          p {
            margin-top: 30px;
            color: white;
            font-size: 18px;
            line-height: 1.66667;
            max-width: 500px;
          }
        }
        .embedded-view {
          display: block;
          // padding-top: 35px;
          // width: 295px;
          // height: 400px;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 9%;
          background-color: $white;
          text-align: center;
          padding: 35px 45px 40px;
          .day-product-label {
            color: #c9c9c9;
            font-family: $font-one;
            font-size: 14px;
            font-weight: 600;
            line-height: 1.79;
            text-transform: uppercase;
            max-width: 240px;
            @include letter_spacing(100);
          }
          .product-of-the-day {
            margin-top: 10px;
            max-width: 240px;
            h2 {
              //text-transform: uppercase;
              //max-width: 240px;
              font-weight: 600;
              margin-bottom: 15px;
            }
            .day-product-description {
              p {
                color: #424242;
                font-size: 15px;
                line-height: 20px;
                margin-bottom: 20px;
              }
            }
            .day-product-img {
              height: 140px;
              @include border_radius(70px);
              display: inline-block;
              overflow: hidden;
            }
            .product-details-link a {
              display: inline-block;
              font-family: $font-title;
              text-transform: uppercase;
              @include border_radius(28px);
              color: #fffefe;
              background-color: #0ab29c;
              border: none;
              font-size: 16px;
              font-weight: 600;
              line-height: 1.6275;
              @include letter_spacing(50);
              padding: 8px 32px;
              margin-top: 25px;
              &:hover {
                background-color: #2fbdab;
                color: #ffffff;
              }
            }
          }
        }
      }
      .center_content {
        .views-field-body {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          z-index: 999;
          text-align: center;
          width: 100%;
          h3 {
            margin-bottom: 72px;
            color: white;
            font-weight: 300;
            line-height: 55px;
            text-transform: none;
            font-weight: 600;
            padding: 0 100px;
          }
          .icon-and-text {
            display: inline-block;
            margin-right: 45px;
            vertical-align: top;
            max-width: 220px;
            .slide-icon {
              margin-bottom: 30px;
            }
            .slide-icon img {
              margin: 0 auto;
            }
            .slide-text {
              p {
                color: white;
                font-family: "A Garamond Pro";
                font-size: 20px;
              }
            }
          }
          .icon-and-text:last-child {
            margin-right: 0;
          }
        }
      }
      .left_content {
        .views-field-body {
          position: absolute;
          z-index: 999;
          left: 9%;
          top: 50%;
          transform: translateY(-50%);
          h3 {
            color: white;
            line-height: 60px;
            font-weight: 300;
            text-transform: none;
            margin-bottom: 40px;
            max-width: 820px;
          }
          .button-fill-green {
            font-weight: 600;
            line-height: 1;
          }
        }
      }
    }
    .slick-dots {
      width: 75px;
      margin: 0;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      left: 50%;
      bottom: 48px;
      transform: translateX(-50%);
    }
    .slick-dots li {
      position: relative;
      width: 10px;
      height: 10px;
      margin: 0 auto;
      border-radius: 50%;
      text-indent: -9999px;
      background-color: #fff;
      opacity: 0.85;
      transition: all 0.2s ease;
      &:hover {
        opacity: 1;
      }
      &:after {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 17px;
        height: 17px;
        background-color: transparent;
      }
    }
    .slick-dots li.slick-active {
      width: 12px;
      height: 12px;
      background-color: #0ab29c;
    }
  }

  .mobile-slide {
    position: relative;
    display: none;
    color: #fff;
    font-family: 'Gilroy', sans-serif;
    font-weight: bold;
    font-size: 36px;
    text-align: center;
    letter-spacing: 2px;
    line-height: 1.2;
    overflow: hidden;
    &__wrapper {
      position: absolute;
      top: 50%; left: 50%;
      transform: translate(-50%, -50%);
    }
    img {
      display: block;
    }
    .title {
      display: block;
      margin-bottom: 20px;
    }
    .button {
      letter-spacing: 1px;
      padding: 9.5px 37px;
      height: 44px;
      white-space: nowrap;
    }
  }

  #content {
    .block {
      margin-bottom: 15px;
      clear: both;
    }
  }

  .home-banner {
    width: calc(50% - 15px);
    max-width: 50%;
    &:first-child {
      margin-right: 30px;
    }
  }
}
