/*=============================================================================
    Forms
   ========================================================================== */
/*
 * Corrects margin displayed oddly in IE6/7
 */
figure,
form {
  margin: 0;
}
/*
 * Define consistent border, margin, and padding
 */
fieldset {
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}
/*
 * 1. Corrects color not being inherited in IE6/7/8/9
 * 2. Corrects text not wrapping in FF3
 * 3. Corrects alignment displayed oddly in IE6/7
 */
legend {
  border: 0;
  padding: 0;
  white-space: normal;
  *margin-left: -7px;
}
/*
 * 1. Corrects font size not being inherited in all browsers
 * 2. Addresses margins set differently in IE6/7, FF3+, S5, Chrome
 * 3. Improves appearance and consistency in all browsers
 */
button,
input,
select,
textarea {
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
  *vertical-align: middle;
}
/*
 * Addresses FF3/4 setting line-height on 'input' using !important in the UA stylesheet
 */
button,
input {
  line-height: normal;
}
/*
 * 1. Improves usability and consistency of cursor style between image-type 'input' and others
 * 2. Corrects inability to style clickable 'input' types in iOS
 * 3. Removes inner spacing in IE7 without affecting normal text inputs
 *    Known issue: inner spacing remains in IE6
 */
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
  cursor: pointer;
  -webkit-appearance: button;
  *overflow: visible;
  transition: all .2s;
}
/*
 * Re-set default cursor for disabled elements
 */
button[disabled],
input[disabled] {
  cursor: default;
}
/*
 * 1. Addresses box sizing set to content-box in IE8/9
 * 2. Removes excess padding in IE8/9
 * 3. Removes excess padding in IE7
 *    Known issue: excess padding remains in IE6
 */
input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0;
  *height: 13px;
  *width: 13px;
}
/*
 * 1. Addresses appearance set to searchfield in S5, Chrome
 * 2. Addresses box-sizing set to border-box in S5, Chrome (include -moz to future-proof)
 */
input[type="search"] {
  -webkit-appearance: textfield;
  box-sizing: content-box;
}
/*
 * Removes inner padding and search cancel button in S5, Chrome on OS X
 */
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}
/*
 * Removes inner padding and border in FF3+
 * www.sitepen.com/blog/2008/05/14/the-devils-in-the-details-fixing-dojos-toolbar-buttons/
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}
/*
 * 1. Removes default vertical scrollbar in IE6/7/8/9
 * 2. Improves readability and alignment in all browsers
 */
textarea {
  overflow: auto;
  vertical-align: top;
}

#edit-cancel {
  text-decoration: none;
  text-transform: uppercase;
  color: #54d682;
  border: 1px solid #54d682;
  border-radius: 5px;
  background: #fff;
}

/* Buttons */
a.tag-button {
  display: block;
  border: 2px solid #0ab29c;
  @include border_radius(18px);
  color: #0ab29c;
  font-family: $font-open-sans;
  font-size: 15px;
  font-weight: 700;
  line-height: 36px;
  //width: 89%;
  padding: 0px 15px;
  //margin-right: 25px;
  text-align: center;
  text-transform: capitalize;
}
a.tag-button:hover {
  background-color: #0ab29c;
  color: white;
}
a.button,
button.button,
#edit-checkout,
input[type="submit"]:not(.delete-line-item),
.checkout-buttons input.checkout-continue,
.checkout-buttons input.checkout-back,
#subscribe .form-submit,
#edit-cancel {
  display: inline-block;
  font-family: $font-title;
  font-size: 18px;
  font-weight: 800;
  line-height: 25px;
  text-transform: uppercase;
  padding: 15.5px 37px;
  @include border_radius(28px);
}
/* Grren buttons */
.checkout-buttons input.checkout-continue,
a.button-border-green {
  color: #0ab29c;  
  border: 3px solid #0ab29c;
  padding: 13px 37px;
  background-color: transparent;
}
.checkout-buttons input.checkout-continue:hover,
a.button-border-green:hover {
  background-color: #0ab29c;
  color: white;
}
a.button-fill-green,
button.button-fill-green,
#edit-checkout,
input[type="submit"]:not(.delete-line-item) {
  color: #fffefe;
  background-color: #0ab29c;
  border: none;
}
a.button-fill-green:hover,
button.button-fill-green:hover,
#edit-checkout:hover,
input[type="submit"]:not(.delete-line-item):hover {
  background-color: #2fbdab;
  color: #ffffff;
}
/* Gray buttons */
a.button-border-gray,
button.button-border-gray {
  color: #424242;  
  border: 3px solid #424242;
  padding: 13px 37px;
  background-color: transparent;
}
a.button-border-gray:hover,
button.button-border-gray:hover {
  background-color: #424242;
  color: white;
}
.checkout-buttons input.checkout-back,
a.button-fill-gray,
#edit-cancel {
  color: #fffefe;
  background-color: #424242;
}
.checkout-buttons input.checkout-back:hover,
a.button-fill-gray:hover,
#edit-cancel:hover {
  background-color: #5e5e5e;
  color: #ffffff;
  text-decoration: none;
}
.delete-line-item {
  width: 18px;
  height: 22px;
  border: none;
  background-color: transparent;
  background-image: url('../images/delete-button.svg');
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
  text-indent: -9999px;
  vertical-align: top;
}
