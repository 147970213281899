/*
 * Headings
 *
 * Addresses font sizes and margins set differently in IE6/7
 * Addresses font sizes within 'section' and 'article' in FF4+, Chrome, S5
 */
h1 {
  font-size: 50px;
  line-height: 50px;
  text-transform: uppercase;
}
h2 {
  font-size: 36px;
  line-height: 36px;
}
h3 {
  font-size: 24px;
  line-height: 24px;
  text-transform: uppercase;
}
h1,h2,h3 {
  font-family: $font-title;
  color: #424242;
  font-weight: 600;
  margin: 0;
}
.page a {
  text-decoration: none;
  color: $black;
  font-family: $font-open-sans;
  transition: all .2s;
}
/*
 * Addresses outline displayed oddly in Chrome
 */
a:focus {
  outline: thin dotted;
  text-decoration: none;
}
/*
 * Improves readability when focused and also mouse hovered in all browsers
 * people.opera.com/patrickl/experiments/keyboard/test
 */
a:active,
a:hover {
  outline: 0;
  text-decoration: none;
}
p {
  font-family: $font-open-sans;
  color: #202020;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
pre,
code,
tt,
samp,
kbd,
var {
  font-family: Consolas, Monaco, 'Courier New', Courier, monospace, sans-serif;
}

/*
 * Addresses styling not present in IE7/8/9, S5, Chrome
 */
abbr[title] {
  border-bottom: 1px dotted;
}

/*
 * Addresses style set to 'bolder' in FF3+, S4/5, Chrome
 */
b,
strong {
  font-weight: 700;
}

blockquote {
  margin: 1em 40px;
}

/*
 * Addresses styling not present in S5, Chrome
 */
dfn {
  font-style: italic;
}

/*
 * Addresses styling not present in IE6/7/8/9
 */
mark {
  background: #ff0;
  color: #000;
}

/*
 * Addresses margins set differently in IE6/7
 */
p,
pre {
  margin: 0 0 1.5em;
}

/*
 * Corrects font family set oddly in IE6, S4/5, Chrome
 * en.wikipedia.org/wiki/User:Davidgothberg/Test59
 */
pre,
code,
kbd,
samp {
  font-family: monospace, serif;
  _font-family: 'courier new', monospace;
  font-size: 1em;
}

/*
 * Improves readability of pre-formatted text in all browsers
 */
pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

/*
 * 1. Addresses CSS quotes not supported in IE6/7
 * 2. Addresses quote property not supported in S4
 */
q {
  quotes: none;
}

q:before,
q:after {
  content: '';
  content: none;
}

/*
 * Prevents sub and sup affecting line-height in all browsers
 * gist.github.com/413930
 */
small,
sub,
sup {
  font-size: 75%;
}

sub,
sup {
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}
