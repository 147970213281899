.page-cart {
  table {
    border-collapse: initial;
    thead {
      th {
        background: #f5f5f5;
        text-align: center;
        &:first-child {
          text-align: start;
        }
      }
    }
    tbody {
      tr {
        padding: 0;
        td {
          padding: 15px 30px 15px 30px;
          background: #ffffff;
          text-align: center;
          &:before {
            content: attr(data-th);
            display: none;
            text-transform: uppercase;
            font-family: "Gilroy", sans-serif;
            color: #424242;
            font-weight: 800;
            font-size: 18px;
          }
          &:first-child {
            text-align: start;
            padding-left: 0;
            display: flex;
            align-items: center;
            &:before {
              display: none;
            }
          }
          &:last-child:before {
            display: none;
          }
        }
        td.views-field-nothing {
          img {
            vertical-align: middle;
            max-width: 170px;
            width: 170px;
          }
          .product-title {
            display: inline-block;
            padding-left: 30px;
            vertical-align: middle;
            box-sizing: border-box;
            max-width: 300px;
            a {
              font: bold 20px/20px $font-title;
              color: #424242;
            }
          }
          .product-weight {
            color: #b1b1b1;
            font-family: "A Garamond Pro", sans-serif;
            font-size: 18px;
            font-weight: 700;
            line-height: 28px;
          }
        }
        td.price {
          color: #424242;
          font: 700 24px/24px "A Garamond Pro";
        }
      }
      tr:first-child {
        td {
          padding-top: 30px;
        }
      }
      tr:last-child {
        td {
          padding-bottom: 40px;
        }
      }
    }
  }
  .view-header {
    font-family: $font-title;
    color: #424242;
    font-size: 36px;
    font-weight: 800;
    line-height: 25px;
    text-align: left;
  }
  .line-item-summary {
    background: #f5f5f5;
    margin-bottom: 0;
    .line-item-total {
      color: #424242;
      font-family: "A Garamond Pro";
      font-size: 24px;
      line-height: 24px;
      font-weight: 700;
      text-transform: uppercase;
      padding: 18px 36px 18px 0;
    }
  }
  .services {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding-top: 40px;
    margin-right: -30px;
    > * {
      width: calc(100% / 4);
      padding-right: 30px;
      padding-bottom: 25px;
      box-sizing: border-box;
    }
    .call_services,
    .free_delivery {
      display: flex;
      align-items: center;
      p {
        font-size: 16px;
        color: #424242;
        margin: 0;
        padding-left: 20px;
      }
    }
    .button {
      width: 100%;
      box-sizing: border-box;
      padding: 13px 0;
      height: 56px;
    }
  }
}

#views-form-commerce-cart-form-default {
  .commerce-quantity-plusminus-link a {
    position: absolute;
    width: 13px;
    height: 13px;
    margin-top: 1px;
    line-height: 17px;
    color: #b1b1b1;
    border: solid 1px;
  }
  .views-field-edit-quantity {
    white-space: nowrap;
    width: 144px;
    min-width: 144px;
  }
  .form-text {
    box-sizing: border-box;
    border-left: none;
    border-right: none;
    border-top: solid 2px #b1b1b1;
    border-bottom: solid 2px #b1b1b1;
    width: calc(100% / 3);
    height: 40px;
    text-align: center;
    float: left;
    font-size: 24px;
    color: #424242;
    font-weight: 600;
    padding: 0;
    border-radius: 0;
  }
  .minus,
  .plus {
    box-sizing: border-box;
    border: solid 2px #b1b1b1;
    color: #b1b1b1;
    width: calc(100% / 3);
    height: 40px;
    line-height: 35px;
    text-align: center;
    cursor: pointer;
    font-size: 20px;
    font-weight: 800;
    float: left;
    user-select: none;
  }
  .minus {
    border-radius: 20px 0 0 20px;
  }
  .plus {
    border-radius: 0 20px 20px 0;
  }
  #edit-submit,
  #edit-checkout {
    display: none;
  }
  #edit-checkout {
    border: 0;
  }
  .back-button {
    position: absolute;
    display: inline-block;
    right: 190px;
    margin-top: 1em;
  }
  .commerce-line-item-actions {
    float: right;
    display: inline-block;
  }
  #delete {
    cursor: pointer;
  }
}

#content h2 {
  //padding: 20px 0;
  //padding-top: 20px;
  margin-bottom: 40px;
}

.block-colorized-gmap h2,
.gm-style-cc {
  display: none !important;
}

.colorized-gmap-common {
  width: 100%;
}

.gmnoprint {
  left: 0px !important;
  bottom: 83px !important;
}

table,
tbody,
td,
th,
thead,
tr {
  border: none;
}

table tr th,
table tr th a,
table tr th a:hover {
  background: #f5f5f5;
}

tbody tr {
  border: none;
}

tr.odd {
  background: #fff;
  border: none;
}
