@font-face {
    font-family: 'Andrea';
    src: url('../fonts/Andrea-Normal.otf'), url('../fonts/Andrea-Normal.woff');
    font-weight: normal;
    font-style: normal;
}
@font-face {
   font-family: "A Garamond Pro";
   src: url("../fonts/Adobe Garamond Pro Regular.ttf");
   font-weight: normal;
}
@font-face {
   font-family: "A Garamond Pro";
   src: url("../fonts/Adobe Garamond Pro Bold.ttf");
   font-weight: bold;
}
@font-face {
   font-family: Gilroy;
   src:local("Gilroy"),
       url("../fonts/Gilroy-light/Gilroy-Light.otf") format("opentype"), url('fonts/Gilroy-light/Gilroy-Light.woff') format('woff'), url('fonts/Gilroy-light/Gilroy-Light.ttf') format('truetype');
   font-weight: 300;
   font-style: normal;
}
@font-face {
    font-family: Gilroy;
    src: local("Gilroy"),
        url("../fonts/Gilroy-bold/Gilroy-ExtraBold.otf") format("opentype"), url('fonts/Gilroy-bold/Gilroy-ExtraBold.woff') format('woff'), url('fonts/Gilroy-bold/Gilroy-ExtraBold.ttf') format('truetype');;
    font-weight: bold;
}
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800');