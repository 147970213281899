#header {
  position: fixed;
  top: 0; left: 0;
  width: 100%;
  background: #232832;
  color: $white;
  z-index: 10;
  .menu-open {
    margin-top: 37px;
    float: right;
    width: 20px;
    height: 20px;
    background: url(../images/menu.png) center center no-repeat;
    cursor: pointer;
  }
  .menu-open:hover {
    background: url(../images/menu-hover.png) center center no-repeat;
  }
}
.admin-menu #header {
  top: 29px;
}
#header-wrapper {
  position: relative;
  height: 92px;
  padding: 0 30px;
  width: auto !important;
}
h1#site-name {
  font-size: 40px;
  font-weight: 300;
  line-height: 30px;
  text-transform: none;
  a {
    color: $white;
    font-family: $font-title;
  }
}
#branding {
  float: left;
  padding-top: 23px;
  #logo, #name-and-slogan {
    float: left;
  }
  #logo {
    margin-right: 13px;
    height: auto;
  }
  #name-and-slogan {
    vertical-align: top;
    h1{
      line-height: 50px;
    }
    a {
      color: white;
      font-family: $font-title;
      font-size: 40px;
      font-weight: 300;
      line-height: 30px;
      vertical-align: text-top;
    }
  }
}

.region-header {
  float: right;

  .header-cart-block {
    position: static;
    display: inline-block;
    margin: 27px 34px 0 0;
    float: right;
    .view-main-content {
      font-family: "Open Sans", sans-serif;
      font-size: 14px;
      box-sizing: border-box;
      position: absolute;
      top: 100%; right: 30px;
      background-color: #fff;
      color: #919191;
      width: 300px;
      padding: 30px;
      padding-top: 70px;
      box-shadow: 0 6px 25px 0 rgba(35, 40, 50, 0.16);
      display: none;
      .views-row {
        margin-bottom: 15px;
        @include clearfix;
      }
      .views-field-field-commerce-image {
        float: left;
        margin-right: 10px;
      }
      .views-field-line-item-title {
        padding-left: 110px;
      }
      .field-content {
        display: inline-block;
      }
      .product-cart-title a,
      .cart-total,
      .field-items,
      .empty-cart {
        font-family: 'Gilroy', sans-serif;
        font-weight: bold;
        color: #424242;
      }
      .product-cart-title {
        font-size: 16px;
      }
      .cart-total {
        position: absolute;
        top: 30px; left: 30px;
        font-size: 20px;
      }
      .field-label,
      .field-items {
        font-size: 18px;
      }
      .field-name-commerce-order-total {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        &:after {
          display: none;
        }
      }
      .button {
        width: 100%;
        height: 44px;
        padding: 9.5px 37px;
        line-height: 18px;
        font-size: 18px;
        white-space: nowrap;
        word-wrap: normal;
      }
      .item-list-pager {
        display: none;
      }
      .empty-cart {
        font-size: 34px;
        line-height: 1.2;
        margin-top: -40px;
        margin-bottom: 20px;
      }
    }
    .my-cart-info {
      position: relative;
      background: url(../images/bag.png) left center no-repeat;
      padding-left: 32px;
      min-height: 36px;
      cursor: pointer;
      .views-label {
        display: block;
        font-family: $font-open-sans;
        color:  #ffffff;
        font-size: 13px;
        font-weight: 700;
        line-height: 12px;
        padding-top: 7px;
      }
      + .commerce-order-handler-area-order-total {
        position: relative;
        top: -15px;
        left: 33px;
        font-size: 14px;
        font-family: 'A Garamond Pro', sans-serif;
        font-weight: bold;
        color: #bcbcbc;
        background: #232832;
        pointer-events: none;
      }
      &:after {
        content: "$0.00";
        display: block;
        position: absolute;
        bottom: -7px;
        left: 33px;
        font-size: 14px;
        font-family: 'A Garamond Pro', sans-serif;
        font-weight: bold;
        color: #bcbcbc;
        pointer-events: none;
      }
    }
  }

  .header-search-block {
    margin-top: 31px;
    .views-submit-button {
      input {
        margin-top: 0;
      }
    }
  }
}

.header-search-block {
  float: right;
  margin-right: 35px;
  .views-submit-button,
  #edit-search-api-views-fulltext-wrapper {
    padding: 0;
  }
  #edit-search-api-views-fulltext {
    background-color: #454b59;
    border-radius: 15px;
    border: 0;
    height: 30px;
    width: 186px;
    padding-left: 20px;
    padding-right: 36px;
    color: white;
    font-family: $font-open-sans;
    font-size: 13px;
    font-weight: 400;
    line-height: 24px;

    &::-webkit-input-placeholder {
      /* Chrome/Opera/Safari */
      color: white;
    }

    &::-moz-placeholder {
      /* Firefox 19+ */
      color: white;
    }

    &:-ms-input-placeholder {
      /* IE 10+ */
      color: white;
    }

    &:-moz-placeholder {
      /* Firefox 18- */
      color: white;
    }
  }
  .views-exposed-widgets {
    position: relative;
  }
  .views-submit-button {
    display: block !important;
    input {
      position: absolute;
      right: 12px;
      top: 8px;
      height: 14px;
      width: 14px;
      margin: 0;
      padding: 0;
      border: 0;
      background: transparent url(../images/search.png) no-repeat center center;
      text-indent: -1000em;
      cursor: pointer;
    }
  }
}

#block-views-product-block-slideshow-block {
  z-index: 998 !important;
}

#menu {
  display: none;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: #232832 url(../images/backmenu.jpg) center center no-repeat;
  .menu-bg-color {
    display: table;
    // position: fixed;
    // top: 0;
    width: 100%;
    height: 100%;
    background: $black-opacity;
  }
  .menu-close {
    background: url(../images/close.png) center center no-repeat;
    width: 24px;
    height:24px;
    position: absolute;
    top: 39px;
    right: 39px;
    cursor: pointer;
  }
}
#menu-wrapper {
  display: table-cell;
  vertical-align: middle;
}
#primary-menu-bar {
  ul {
    padding: 0;
    margin: 0;
    li {
      padding: 0;
      margin: 0 0 15px 0;
      text-align: center;
      a {
        color: $white;
        font-family: $font-title;
        font-size: 25px;
        font-weight: 600;
        text-transform: uppercase;
        @include letter_spacing(220);
        transition: all 0.2s;
        &:hover {
          color: #0ab29c;
          letter-spacing: 0.15em;
        }    
        &:focus {
          border: 0;
          outline: 0;
        }
      }
    }
  }
}
.menu-copyright {
  text-align: center;
  margin-top: 50px;
  p {
    color: $white;
  }
}

