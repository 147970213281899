html {
  position: relative;
  height: 100%;
  overflow-y: visible;
}
body {
  font-family: "Open Sans", sans-serif;
  height: 100%;
  position: relative;
  overflow-x: hidden;
}

div#columns *::selection {
  background: $selection_color;
  color: white;
}

#columns,
#footer-first #footer-columns,
#footer-second #footer-columns,
#header-wrapper,
#menu-wrapper,
#messages,
#partners,
#second-footer #footer-columns,
#slider,
#triptych-wrapper,
.breadcrumb {
  width: 100%;
  max-width: 1170px;
  margin: 0 auto;
}
/* =============================================================================
 *   Tables
 * ========================================================================== */
table {
  width: 100%;
  margin: 10px 0;
  padding: 0;
}

table.sticky-header {
  z-index: 10;
}

table,
tbody,
td,
th,
thead,
tr {
  border-color: #ccc;
}

table tr th,
table tr th a,
table tr th a:hover {
  font-weight: bold;
  color: #606060;
  background: $white;
}

table,
td,
th {
  vertical-align: middle;
}

caption,
td,
th {
  text-align: left;
}

thead tr {
  font-weight: 700;
  background-color: $white;
}

td,
th {
  margin: 0;
  padding: 5px 7px;
  border-bottom: 0;
}

tbody tr {
  border-top: 1px solid #ccc;
}

tr.odd {
  background: whitesmoke;
  border-bottom: 1px solid #ccc;
}

tr.even,
tr.info,
tr:nth-child(2n+2) {
  border-bottom: 0;
  background-color: $white;
}

tr.odd td.active {
  background-color: whitesmoke;
}

tr.even td.active {
  background-color: #ebebeb;
}
/*
 * 1. Remove most spacing between table cells
 */
table {
  border: 1px solid;
  border-spacing: 0;
  border-collapse: collapse;
  font-size: inherit;
  font: 100%;
}
/* =============================================================================
    Lists
   ========================================================================== */
/*
 * Addresses margins set differently in IE6/7
 */
dl,
menu,
ol,
ul {
  margin: 1em 0;
}

dd {
  margin: 0 0 0 40px;
}
/*
 * Addresses paddings set differently in IE6/7
 */
menu,
ol,
ul {
  padding: 0 0 0 40px;
}
/*
 * Corrects list images handled incorrectly in IE7
 */
nav ol,
nav ul {
  list-style: none;
  list-style-image: none;
}
h2,
h3,
.views-field {
  white-space: normal;
  word-wrap: break-word;
}

input[type="text"],
input[type="password"] {
  box-sizing: border-box;
  display: block;
  height: 50px;
  padding: 0 20px;
  border: 2px solid #ccc;
  border-radius: 2px;
  line-height: 50px;
  color: #606060;
  transition: border-color 0.2s ease;

  &:focus {
    outline: none;
    border-color: $selection_color;
  }
}
