/*
 * Corrects block display not defined in IE6/7/8/9 & FF3
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
}
/*
 * Corrects inline-block display not defined in IE6/7/8/9 & FF3
 */
audio,
canvas,
video {
  display: inline-block;
  *display: inline;
  *zoom: 1;
}
/*
 * Prevents modern browsers from displaying 'audio' without controls
 * Remove excess height in iOS5 devices
 */
audio:not([controls]) {
  display: none;
  height: 0;
}
/*
 * Addresses styling for 'hidden' attribute not present in IE7/8/9, FF3, S4
 * Known issue: no IE6 support
 */
[hidden] {
  display: none;
}

body {
  margin: 0;
}

ul li,
ul li.leaf {
  list-style: none;
}
