.how-it-works-page.page-banner {
  h3 {
    color: #ffffff;
  }
}
.how-it-works.steps-block {
  margin: 0 auto;
  margin-bottom: 70px;
  text-align: center;
  h2 {
    margin-bottom: 26px;
    line-height: 44px;
  }
  .steps-wrapper {
    margin-right: -70px;
    .step, .arrow {
      display: inline-block;
      vertical-align: top;
    }
    .step {
      margin-right: 70px;
      margin-bottom: 30px;
      .step-img {
        margin-bottom: 37px;
      }
      .step-title {
        h3 {
          color: #606060;
          letter-spacing: 1.8px;
        }
        margin-bottom: 25px;
      }
      .step-description {
        p {
          color: #424242;
          font-family: "A Garamond Pro";
          font-size: 20px;
          line-height: 26px;
        }
      }
    }
    .after-step-1 {
      margin-left: -75px;
      margin-right: -40px;
    }
    .after-step-2 {
      margin-left: -40px;
      margin-right: -55px;
    }
    .after-step-3 {
      margin-left: -55px;
      margin-right: -65px;
    }
  }
}
.how-it-works-page.contact-us-banner {
  float: left;
  margin-right: 5%;
  width: 30%; 
  background: rgba(153, 255, 204, 0.7);
  text-align: center;
  overflow: hidden;
  padding: 50px 0;
  h2 {
    margin-bottom: 30px;
  }
  .contact-banner-img {
    margin-bottom: 40px;
  }
}
.how-it-works-page.frequently-asked-questions {
  width: 55%;
  padding: 50px 5%;
  background-color: #f5f5f5;
  float: left;
  h2 {
    text-align: center;
    margin-bottom: 30px;
  }
  .views-row {
    position: relative;
    padding: 23px 15px 20px 19px;
    border-top: 2px solid #b1b1b1;
    overflow: hidden;
    .question-answer-wrapper {
      float: left;
      margin-right: 26px;
      //width: 620px;
      .question {
        color: #202020;
        font-family: $font-open-sans;
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
      }
    }
    .show-hide-button {
      position: absolute;
      top: 23px;
      right: 15px;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }
  .views-row:last-child {
    border-bottom: 2px solid #b1b1b1;
  }
  .views-row.expanded {
    .question {
      margin-bottom: 25px;
      span {
        font-weight: 700;
      }
    }
    .show-hide-button {
      background: url(../images/hide-button.png) center center no-repeat;
    }
  }  
  .views-row.collapsed {
    .answer {
      display: none;
    }
    .show-hide-button {
      background: url(../images/show-button.png) center center no-repeat;
    }
  }
}
